import styled, { css } from 'styled-components';

import addAlpha from 'utils/addAlpha';
import { ZIndex } from 'styles/metrics';
import { TextEllipsis } from 'styles/mixins';

export const MobileTableContainer = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.125rem 0.5rem rgba(107, 124, 147, 0.2);
  overflow: hidden;
  margin: 0 1rem 1rem 1rem;
  padding: 0;
  margin-top: 1.125rem;
`;

export const RowContainer = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 0.875rem 0.75rem;
  row-gap: 0.3125rem;

  :not(:last-child) {
    border-bottom: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
  }
`;

export const Column = styled.div`
  :not(:last-child) {
    margin-right: 0.75rem;
    min-width: 0;
  }

  :last-child {
    margin-left: auto;
    flex-shrink: 0;
  }
`;

export const Name = styled.h3`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 1.375rem;
  margin-bottom: 0.3125rem;
  ${TextEllipsis};
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.purple3};
`;

export const InfoItem = styled.span`
  position: relative;

  :not(:first-child) {
    margin-left: 0.75rem;

    &:before {
      content: '·';
      position: absolute;
      left: -0.4375rem;
    }
  }
`;

export const Datetime = styled.p`
  color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.75)};
`;

export const Date = styled.span``;

export const Time = styled.span`
  margin-left: 0.75rem;
  position: relative;

  &:before {
    content: '·';
    position: absolute;
    left: -0.4375rem;
  }
`;

export const FilterContainer = styled.div<{ $isStuck: boolean }>`
  position: sticky;
  top: -1px;
  background: ${({ theme }) => theme.colors.purple1};
  z-index: ${ZIndex.TABLE_FILTER};
  padding: 0 1rem;
  padding-top: 1.5625rem;

  ${({ $isStuck }) =>
    $isStuck &&
    css`
      border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.purple2};
    `};
`;

export const Container = styled.div`
  display: flex;
  margin-top: 0.75rem;
  padding-left: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;
