import { Route, Switch } from 'react-router-dom';

import WrongPlanChoice from 'containers/Common/WrongPlanChoice';
import Validation from 'containers/Common/Validation';
import AuthenticationRouter from 'containers/AuthenticationRouter';
import { AuthProvider } from 'data/context/AuthContext';
import GoogleAuthContextProvider from 'data/context/GoogleAuth';

const Root = () => {
  return (
    <GoogleAuthContextProvider>
      <AuthProvider>
        <Switch>
          <Route exact component={WrongPlanChoice} path="/wrong-plan" />
          <Route
            exact
            component={Validation}
            path="/validation/:userKeyEncoded/:formId/:responseId/:emailValidatorEncoded/:displayAwaiting/:collectEmailAddress/:hasMarkerSignature"
          />
          <Route path="/">
            <AuthenticationRouter />
          </Route>
        </Switch>
      </AuthProvider>
    </GoogleAuthContextProvider>
  );
};

export default Root;
