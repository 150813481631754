import { FC, useCallback, useMemo } from 'react';
import {
  useTable,
  useFlexLayout,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from 'react-table';
import StickySentinel from 'components/StickySentinel';
import { sortDate } from 'utils/sortDate';
import { getAccessor } from 'utils/getAccessor';
import { DateFilter, Props } from './types';
import { Table, Header, Body, FilterWrapper, FilterContainer } from './styles';

import Pagination from './Pagination';
import Columns from './Columns';
import Rows from './Rows';
import {
  getDefaultSort,
  useSticky,
  dateFilterFunction,
  globalFilterFunction,
} from './logic';
import Placeholder from './Placeholder';
import Empty from './Empty';

const DesktopTable: FC<Props> = ({
  columns,
  data,
  loading,
  empty,
  notFound,
  defaultDateFilter = 'LAST_12_MONTHS',
  disableFilter = false,
  filter: FilterComponent,
  exporter: ExportComponent,
  itemsPerPage = 25,
  rowLink,
  ...props
}) => {
  const defaultColumn = useMemo(
    () => ({
      width: 150,
    }),
    [],
  );

  const defaultSorted = useMemo(() => getDefaultSort(columns), [columns]);
  const dateColumn = useMemo(
    () => getAccessor(columns.find((column) => column.filter === 'unix')),
    [columns],
  );
  const filters = useMemo(
    () =>
      !disableFilter && dateColumn
        ? [{ id: dateColumn, value: defaultDateFilter }]
        : [],
    [disableFilter, dateColumn, defaultDateFilter],
  );

  const {
    state: { pageIndex, globalFilter },
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    headers,
    page,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: itemsPerPage,
        sortBy: defaultSorted ? [defaultSorted] : [],
        filters,
      },
      sortTypes: {
        datetime: sortDate,
      },
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetPage: false,
      disableFilters: disableFilter,
      globalFilter: globalFilterFunction,
      filterTypes: {
        unix: dateFilterFunction,
      },
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const dateFilter = useCallback(
    (value: DateFilter) => {
      if (!dateColumn) return;
      setFilter(dateColumn, value);
    },
    [dateColumn, setFilter],
  );

  const { isStuck, onSticky } = useSticky();
  const isLoading = loading && page.length === 0;
  const isEmpty =
    !loading && page.length === 0 && (globalFilter || '').length === 0;
  const isNotFound =
    !loading && page.length === 0 && (globalFilter || '').length > 0;
  return (
    <>
      <StickySentinel onSticky={onSticky} />
      {FilterComponent && (
        <FilterWrapper>
          <FilterContainer>
            <FilterComponent
              onChange={setGlobalFilter}
              onDateFilterChange={dateFilter}
              currentDateFilter={defaultDateFilter}
              goToPage={gotoPage}
            />
            {ExportComponent ? <ExportComponent data={data} /> : null}
          </FilterContainer>
        </FilterWrapper>
      )}
      {isLoading ? <Placeholder columns={columns} animated /> : null}
      {isEmpty || isNotFound ? (
        <Empty
          foreground={isNotFound ? notFound : empty}
          background={<Placeholder columns={columns} />}
        />
      ) : null}
      {!isLoading && !isEmpty && !isNotFound && (
        <>
          <Table {...props}>
            <Header $isStuck={isStuck}>
              <tr>
                <Columns headers={headers} originalColumns={columns} />
              </tr>
            </Header>
            <Body>
              <Rows
                prepareRow={prepareRow}
                rows={page}
                originalColumns={columns}
                rowLink={rowLink}
              />
            </Body>
          </Table>
          <Pagination
            current={pageIndex}
            pages={pageCount}
            hasNext={canNextPage}
            hasPrevious={canPreviousPage}
            goToPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </>
      )}
    </>
  );
};

export default DesktopTable;
