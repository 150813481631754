import styled, { css } from 'styled-components';
import { from } from 'styles/media';
import { ReactComponent as DefaultErrorIcon } from 'assets/images/icons/alert-circle.svg';
import addAlpha from 'utils/addAlpha';
import { ComponentProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.purple4};
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 0.75rem;
`;

export const Optional = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: -0.66%;
  line-height: 1.375rem;
  display: block;

  ${from.mobile} {
    display: inline-block;
  }
`;

export const ComponentContainer = styled.div`
  position: relative;
  display: flex;
`;

export const Component = styled.textarea<ComponentProps>`
  background-color: ${({ theme }) => addAlpha(theme.colors.purple2, 0.25)};
  border: 0.0625rem solid
    ${({ $hasError, theme }) =>
      $hasError ? theme.colors.red600 : theme.colors.purple2};
  border-radius: 0.3125rem;
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.375rem;
  height: 2.5rem;
  outline: 0;
  padding: ${({ $hasError }) =>
    $hasError ? '0.5625rem 2.5rem 0.5625rem 1rem' : '0.5625rem 1rem'};
  width: 100%;
  resize: vertical;
  min-height: 5rem;
  font-family: 'Inter';
  transition: border 300ms ease;

  &:disabled {
    color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
    cursor: not-allowed;
  }

  ${({ $hasError, theme }) =>
    $hasError
      ? null
      : css`
          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            border: 0.0625rem solid ${addAlpha(theme.colors.purple3, 0.5)};
          }
        `}

  &::placeholder {
    color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:-webkit-autofill::first-line {
    color: inherit;
    font-family: Inter;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.red600};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  min-height: 1.25rem;
  display: flex;
  align-items: center;
`;

export const ErrorIcon = styled(DefaultErrorIcon)`
  color: ${({ theme }) => theme.colors.red600};
  height: 1.25rem;
  position: absolute;
  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
`;
