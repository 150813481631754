import testingVar from 'utils/testingVar';
import { Title, Text, CheckCircleIcon } from '../styles';

const InfoRequestedCard = () => {
  return (
    <>
      <CheckCircleIcon />
      <Title data-test-id={testingVar('validation-workflow-feedback')}>
        Your request for additional information has been sent
      </Title>
      <Text>Thanks for using Form Publisher</Text>
    </>
  );
};

export default InfoRequestedCard;
