import { nanoid } from 'nanoid';

import HelloFreshLogoWebp from 'assets/images/logos/hello-fresh-logo.webp';
import HelloFreshLogoPng from 'assets/images/logos/hello-fresh-logo.png';
import WhirpoolLogoWebp from 'assets/images/logos/whirpool-logo.webp';
import WhirpoolLogoPng from 'assets/images/logos/whirpool-logo.png';
import PrincetonUniversityLogoWebp from 'assets/images/logos/princeton-university-logo.webp';
import PrincetonUniversityLogoPng from 'assets/images/logos/princeton-university-logo.png';
import NYULogoWebp from 'assets/images/logos/nyu-logo.webp';
import NYULogoPng from 'assets/images/logos/nyu-logo.png';
import HMLogoWebp from 'assets/images/logos/hm-logo.webp';
import HMLogoPng from 'assets/images/logos/hm-logo.png';
import UberLogoWebp from 'assets/images/logos/uber-logo.webp';
import UberLogoPng from 'assets/images/logos/uber-logo.png';
import GrabLogoWebp from 'assets/images/logos/grab-logo.webp';
import GrabLogoPng from 'assets/images/logos/grab-logo.png';
import NewRelicLogoWebp from 'assets/images/logos/new-relic-logo.webp';
import NewRelicLogoPng from 'assets/images/logos/new-relic-logo.png';
import Companies, { ImageFormat } from './types';

const marketPlaceLogos: Companies = {
  'hello-fresh': {
    png: HelloFreshLogoPng,
    webp: HelloFreshLogoWebp,
    alt: 'HelloFresh Logo',
  },
  whirpool: {
    png: WhirpoolLogoPng,
    webp: WhirpoolLogoWebp,
    alt: 'Whirpool Logo',
  },
  princeton: {
    png: PrincetonUniversityLogoPng,
    webp: PrincetonUniversityLogoWebp,
    alt: 'Princeton University Logo',
  },
  nyu: {
    png: NYULogoPng,
    webp: NYULogoWebp,
    alt: 'NYU Logo',
  },
  hm: {
    png: HMLogoPng,
    webp: HMLogoWebp,
    alt: 'H&M Logo',
  },
  uber: {
    png: UberLogoPng,
    webp: UberLogoWebp,
    alt: 'Uber Logo',
  },
  grab: {
    png: GrabLogoPng,
    webp: GrabLogoWebp,
    alt: 'Grab Logo',
  },
  'new-relic': {
    png: NewRelicLogoPng,
    webp: NewRelicLogoWebp,
    alt: 'New Relic Logo',
  },
};

const marketPlaceLogosWithId = Object.keys(marketPlaceLogos).reduce<
  Record<string, ImageFormat>
>((acc, cu) => {
  acc[cu] = { id: nanoid(), ...marketPlaceLogos[cu] };
  return acc;
}, {});

export default marketPlaceLogosWithId;
