import {
  isWithinInterval,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  endOfMonth,
  endOfYear,
} from 'date-fns';
import { useCallback, useState } from 'react';
import { Row } from 'react-table';
import { Column, DateFilter } from './types';

function getCurrentDate() {
  return process.env.REACT_APP_TESTING_E2E &&
    process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'
    ? new Date('2020-12-01 10:40 AM')
    : new Date();
}

function getDateFilter(filter: DateFilter) {
  switch (filter) {
    case 'LAST_30_DAYS':
      return { start: subDays(getCurrentDate(), 30), end: getCurrentDate() };
    case 'LAST_MONTH': {
      const lastMonth = subMonths(getCurrentDate(), 1);
      const firstOfLastMonth = startOfMonth(lastMonth);
      const lastOfLastMonth = endOfMonth(lastMonth);
      return { start: firstOfLastMonth, end: lastOfLastMonth };
    }
    case 'LAST_12_MONTHS':
      return { start: subMonths(getCurrentDate(), 12), end: getCurrentDate() };
    case 'THIS_MONTH':
      return {
        start: startOfMonth(getCurrentDate()),
        end: endOfMonth(getCurrentDate()),
      };
    case 'THIS_YEAR':
    default:
      return {
        start: startOfYear(getCurrentDate()),
        end: endOfYear(getCurrentDate()),
      };
  }
}

function isWithinTheRange(date: string | Date, filter: DateFilter) {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return isWithinInterval(parsedDate, getDateFilter(filter));
}

export function getDefaultSort(columns: Column[]) {
  const column = columns.find((item) => item.defaultSorted !== undefined);
  if (!column || typeof column.accessor !== 'string') return undefined;
  return { id: column.accessor, desc: column.defaultSorted === 'desc' };
}

export function dateFilterFunction(
  rows: Row[],
  ids: string[],
  filterValue: DateFilter,
) {
  const [column] = ids;
  const filteredRows = rows.filter((row) =>
    isWithinTheRange(row.values[column], filterValue),
  );
  return filteredRows;
}

export function globalFilterFunction(
  rows: Row[],
  ids: string[],
  filterValue: string,
) {
  const filteredRows = rows.filter((row) => {
    return ids.some((id: string) => {
      const rowValue = row.values[id];
      return String(rowValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());
    });
  });
  return filteredRows;
}

export const useSticky = () => {
  const [isStuck, setIsStuck] = useState(false);
  const onSticky = useCallback((value: boolean) => setIsStuck(value), []);
  return { isStuck, onSticky };
};
