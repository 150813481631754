import { FC, memo } from 'react';

import { ReactComponent as Dashboard } from 'assets/images/icons/list.svg';
import { ReactComponent as Team } from 'assets/images/icons/team.svg';
import { AnimatePresence } from 'framer-motion';
import testingVar from 'utils/testingVar';
import {
  Container,
  Header,
  Title,
  Logo,
  Navigation,
  Item,
  ItemIcon,
  Overlay,
  TopSection,
  // BottomSection,
} from './styles';
import { Props } from './types';
// import SmallCannonNavBanner from '../SmallCannonNavBanner';

const MobileMenu: FC<Props> = ({
  canAccessTeamReport,
  open,
  closeMenu,
  cannon,
  ...props
}) => {
  const handleClick = () => closeMenu();

  return (
    <>
      <AnimatePresence>
        {open && (
          <Overlay
            data-test-id={testingVar('submit-close')}
            key="menu-overlay"
            onClick={closeMenu}
          />
        )}
      </AnimatePresence>
      <Container data-test-id={testingVar('menu')} {...props} $open={open}>
        <TopSection>
          <Header>
            <Logo />
            <Title>Form Publisher</Title>
          </Header>
          <Navigation>
            <Item to="/dashboard" onClick={handleClick}>
              <ItemIcon as={Dashboard} />
              Dashboard
            </Item>
            {canAccessTeamReport && (
              <Item to="/team-report" onClick={handleClick}>
                <ItemIcon as={Team} />
                Team report
              </Item>
            )}
          </Navigation>
        </TopSection>
        {/* Enable this whenever it's necessary */}
        {/* <BottomSection>
          {cannon && cannon.enabled && <SmallCannonNavBanner cannon={cannon} />}
        </BottomSection> */}
      </Container>
    </>
  );
};

export default memo(MobileMenu);
