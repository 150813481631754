import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import 'core-js';
import 'regenerator-runtime/runtime';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { getRootHost } from 'utils/getRootHost';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';
import { getEnvironmentLowerCased } from './utils/indexUtils';

if (getRootHost(window.location.hostname) !== 'localhost') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getEnvironmentLowerCased(),
    release: 'form-publisher-webapp@1.1.0',
    ignoreErrors: [/^User is not signed in$/],
  });
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_IS_MOCK) {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');

  worker.start();
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
