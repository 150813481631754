export type Stat = {
  filesGeneratedThisMonth: number;
  totalFilesGenerated: number;
};

export const normalizeStat = (input: Stat) => {
  return {
    totalFilesGenerated: input.totalFilesGenerated ?? 0,
    totalGeneratedThisMonth: input.filesGeneratedThisMonth ?? 0,
  } as const;
};
