import { postCancelAutoRenewal } from 'data/requests/cancelRenewal';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export const useCancelAutoRenewal = () => {
  const { mutateAsync } = useMutation(postCancelAutoRenewal);
  const cancelAutoRenewal = useCallback(
    async ({ subscriptionId }: { subscriptionId?: string }) => {
      if (!subscriptionId) throw new Error('Subscription Id is missing');
      await mutateAsync({ subscriptionId });
    },
    [mutateAsync],
  );
  return cancelAutoRenewal;
};
