import { FC } from 'react';
import { ReactComponent as RejectIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import ValidationButton from 'components/ValidationButton';
import { Formik } from 'formik';
import testingVar from 'utils/testingVar';
import { Title, Form, ButtonsRow, SignatureInput, Textarea } from '../styles';
import useLogic from '../logic';
import { FormProps } from '../types';

const RequestInfo: FC<FormProps> = ({ filename, needsSignature, imgSrc }) => {
  const { status, setStatus, handleSubmitForm, validationSchema } = useLogic({
    imgSrc: imgSrc ?? '',
    commentsRequired: true,
    signatureRequired: !!needsSignature,
  });

  return (
    <>
      <Title data-test-id="validation-workflow-info-requested">
        Please approve, reject or request more information about this document:{' '}
        {filename}
      </Title>
      <Formik
        initialValues={{ comments: '', signature: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({ isSubmitting }) => (
          <Form>
            {needsSignature && (
              <SignatureInput
                name="signature"
                label="Add your signature"
                imgSrc={imgSrc}
              />
            )}
            <Textarea
              label="Add comments"
              name="comments"
              placeholder="Leave your comments..."
              addonMessage="for requesting more information"
              data-test-id={testingVar('validation-workflow-comments-textarea')}
            />
            <ButtonsRow>
              <ValidationButton
                type="submit"
                color="primary"
                variant="outlined"
                icon={<RejectIcon />}
                fullWidth
                loading={status === 'REJECTED' && isSubmitting}
                disabled={status !== 'REJECTED' && isSubmitting}
                data-test-id={testingVar('validation-workflow-reject-button')}
                onClick={() => {
                  setStatus('REJECTED');
                }}
              >
                Reject
              </ValidationButton>
              <ValidationButton
                type="submit"
                color="secondary"
                variant="outlined"
                icon={<CheckIcon />}
                fullWidth
                loading={status === 'APPROVED' && isSubmitting}
                disabled={status !== 'APPROVED' && isSubmitting}
                data-test-id={testingVar('validation-workflow-approve-button')}
                onClick={() => {
                  setStatus('APPROVED');
                }}
              >
                Approve
              </ValidationButton>
              <ValidationButton
                type="submit"
                color="tertiary"
                variant="outlined"
                icon={<InfoIcon />}
                fullWidth
                loading={status === 'FEEDBACK' && isSubmitting}
                disabled={status !== 'FEEDBACK' && isSubmitting}
                data-test-id={testingVar('validation-workflow-feedback-button')}
                onClick={() => {
                  setStatus('FEEDBACK');
                }}
              >
                Request Info
              </ValidationButton>
            </ButtonsRow>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RequestInfo;
