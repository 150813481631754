import testingVar from 'utils/testingVar';
import { Title, Text, IconContainer, FileIcon, RejectedIcon } from '../styles';

const RejectedCard = () => {
  return (
    <>
      <IconContainer>
        <FileIcon />
        <RejectedIcon />
      </IconContainer>
      <Title data-test-id={testingVar('validation-workflow-rejected')}>
        The file has been rejected
      </Title>
      <Text>Thanks for using Form Publisher</Text>
    </>
  );
};

export default RejectedCard;
