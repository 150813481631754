import styled from 'styled-components';

import { from } from 'styles/media';

import { ReactComponent as FormPublisherLogo } from 'assets/images/brand/form-publisher-logo-circle.svg';
import { ReactComponent as Alert } from 'assets/images/icons/alert-triangle.svg';
import DefaultLoader from 'components/Loader';
import addAlpha from 'utils/addAlpha';

export const LoginContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.purple1};
  display: grid;
  grid-template-areas:
    'main'
    'footer'
    'aside';
  grid-template-rows: 1fr auto;
  height: 100vh;

  ${from.laptop} {
    grid-template-areas:
      'main aside'
      'footer aside';
    grid-template-columns: 1fr 30rem;
  }
`;

export const Main = styled.main`
  grid-area: main;
  align-self: center;
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  margin: 1.375rem auto;
  max-width: 30rem;
  padding: 1.25rem 0.625rem;
  text-align: center;

  ${from.mobile} {
    margin: 1.25rem auto;
    padding: 2.5rem 5rem;
  }
`;

export const Image = styled(FormPublisherLogo)`
  margin-bottom: 1rem;

  width: 3.75rem;
  height: 3.75rem;

  ${from.smallMobile} {
    margin-bottom: 1.5rem;
  }

  ${from.tablet} {
    margin-bottom: 1.5rem;
    width: 6rem;
    height: 6rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.lexendDeca};

  ${from.tablet} {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4rem;
  letter-spacing: -0.0066em;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.purple3};

  ${from.tablet} {
    margin-bottom: 1rem;
  }
`;

export const Footer = styled.footer`
  grid-area: footer;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: center;

  ${from.smallMobile} {
    margin: 1.5rem 0;
  }
`;

export const Nav = styled.nav``;

export const NavLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  display: inline-block;
  letter-spacing: -0.0033em;
  margin: 0.5rem 0.625rem;
  position: relative;

  ${from.mobile} {
    :not(:last-child) {
      &:after {
        content: '·';
        position: absolute;
        right: -0.75rem;
      }
    }
  }
`;

export const Aside = styled.aside`
  display: none;
  grid-area: aside;
  background-color: ${({ theme }) => theme.colors.purpleSidebar};
  color: ${({ theme }) => theme.colors.white};
  padding: 10.4375rem 5.25rem 4rem;
  text-align: center;

  @media (max-height: 46.875rem) {
    padding-top: 8vh;
  }

  @media (min-height: 46.9375rem) and (max-height: 56.25rem) {
    padding-top: 12vh;
  }

  ${from.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  letter-spacing: -0.01em;
  line-height: 1.625rem;
  margin: 0 auto;
  max-width: 17.5rem;
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
`;

export const ErrorWrapper = styled.div`
  min-height: 3.5rem;
  line-height: 1.225rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.purple3};
  margin-bottom: 1.5rem;
`;

export const ErrorContainer = styled.div`
  border: 0.0625rem solid ${({ theme }) => addAlpha(theme.colors.purple2, 0.5)};
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.625rem;
  text-align: start;

  a {
    color: ${({ theme }) => theme.colors.purple4};
  }

  b {
    font-weight: ${({ theme }) => theme.weights.medium};
  }
`;

export const AlertIcon = styled(Alert)`
  color: ${({ theme }) => theme.colors.yellow700};
  margin-right: 0.6625rem;
`;

export const Loader = styled(DefaultLoader)`
  margin-top: 1rem;
`;

export const NewUsersDescription = styled.span`
  display: block;
  color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.75)};
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const Logos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
  margin: 5.25rem 0;
`;

export const Logo = styled.picture`
  img {
    width: 8.5rem;
  }
`;

export const Badges = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.375rem;
`;

export const Badge = styled.picture`
  :nth-child(1) {
    width: 1.8125rem;
    height: 1.8125rem;
  }
  :nth-child(2) {
    width: 13.5625rem;
    height: 1.8125rem;
  }
`;

export const AgreeToSText = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple3};
  font-size: 0.75rem;
  line-height: 160%;
  margin-top: 1rem;
  letter-spacing: -0.0066em;
  display: block;
`;

export const TosLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  color: ${({ theme }) => theme.colors.purple4};
`;

export const ReviewsLink = styled.a.attrs(() => ({
  rel: 'nofollow noopener noreferrer',
  target: '_blank',
}))`
  text-decoration: none;
  margin-top: auto;
`;

export const ReviewsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
