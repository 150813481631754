import { FC } from 'react';

import animations from './animations';
import { Root } from './styles';
import { Props } from './types';

const BaseModal: FC<Props> = ({
  animation = 'fade',
  children,
  fullScreen,
  onClose,
  open,
  ...rest
}) => (
  <Root
    TransitionComponent={animations[animation]}
    closeAfterTransition
    fullScreen={fullScreen}
    onClose={onClose}
    open={open}
    {...rest}
  >
    {children}
  </Root>
);

export default BaseModal;
