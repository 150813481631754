import { FC, memo } from 'react';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { ReactComponent as GoogleLogoIcon } from 'assets/images/icons/google.svg';
import { ReactComponent as FacebookCircleIcon } from 'assets/images/icons/facebook-circle.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/images/icons/microsoft.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/icons/twitter.svg';

import {
  IconWrapper,
  TextWrapper,
  Button as StyledButton,
  Loader,
} from './styles';
import { Props } from './types';

const SocialLogInButton: FC<Props> = ({
  children,
  socialPlatform,
  fullWidth = true,
  loading = false,
  ...props
}) => {
  const handleIconRendering = () => {
    switch (socialPlatform) {
      case 'google':
        return <GoogleLogoIcon />;
      case 'facebook':
        return <FacebookCircleIcon />;
      case 'twitter':
        return <TwitterIcon />;
      default:
      case 'microsoft':
        return <MicrosoftIcon />;
    }
  };

  return (
    <StyledButton
      $fullWidth={fullWidth}
      $socialPlatform={socialPlatform}
      {...props}
    >
      <IconWrapper>{handleIconRendering()}</IconWrapper>
      <TextWrapper>
        Sign in with {capitalizeFirstLetter(socialPlatform)}
      </TextWrapper>
      {loading ? <Loader data-test-id="loader" /> : null}
    </StyledButton>
  );
};

export default memo(SocialLogInButton);
