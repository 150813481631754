import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Container } from './styles';
import Login from './Login';

const Guest = () => {
  const { pathname, search } = useLocation();
  const previousAuthenticatedPath = useMemo(
    () => `${pathname}${search}`,
    [pathname, search],
  );
  const redirectPath = useMemo(() => {
    if (previousAuthenticatedPath === '/') return '/login';
    return `/login?continue=${encodeURIComponent(previousAuthenticatedPath)}`;
  }, [previousAuthenticatedPath]);
  const reload = () => window.location.reload();
  return (
    <Container>
      <Switch>
        <Route path="/internet-explorer-warning.html" onEnter={reload} />
        <Route component={Login} exact path="/login" />
        <Route path="/">
          <Redirect to={redirectPath} />
        </Route>
      </Switch>
    </Container>
  );
};

export default Guest;
