import { FC, memo, useEffect, useRef } from 'react';
import { Container } from './styles';
import { Props } from './types';

const TextWrap: FC<Props> = ({ children, ...props }) => {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (
      ref.current &&
      ref.current.scrollWidth > ref.current.offsetWidth &&
      ref.current.textContent
    ) {
      ref.current.title = ref.current.textContent;
    }
  }, [ref]);
  return (
    <Container {...props} ref={ref}>
      {children}
    </Container>
  );
};

export default memo(TextWrap);
