import Select from 'react-select';
import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

const SelectStyles = css`
  .react-select__control {
    background: none;
    border: none;
    border-left: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
    border-radius: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.purple3};
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.375rem;
    min-height: auto;
    min-width: 11rem;
    padding-left: 0.875rem;
    position: relative;

    &:hover {
      border-color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
    }

    &::before {
      content: '';
      position: absolute;
      background: ${({ theme }) => addAlpha(theme.colors.purple3, 0.2)};
      top: 50%;
      left: 0%;
      width: 85%;
      height: 125%;
      border-radius: 4px;
      transform: translate(1rem, -50%);
      z-index: -1;
      opacity: 0;
      transition: opacity 150ms ease-out;
    }

    &--is-focused.react-select__control--menu-is-open::before {
      opacity: 0;
    }

    &--is-focused::before {
      opacity: 1;
    }
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.purple3};
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.colors.purple4};
    display: flex;
    padding: 0 0.875rem;
  }

  .react-select__menu {
    box-shadow: 0rem 0.25rem 0.75rem rgba(107, 124, 147, 0.2);
    color: inherit;
    margin-top: 1rem;
    overflow: hidden;
  }

  .react-select__menu-list {
    padding: 0;

    .react-select__option {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.purple3};
      cursor: pointer;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 0.75rem 1.4375rem;

      &:hover {
        background: ${({ theme }) => theme.colors.purple1};
      }

      &:active {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.purple4};
        font-weight: 500;
      }

      &--is-focused {
        background: ${({ theme }) => theme.colors.purple1};
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  ${SelectStyles}
`;
