import { FC, memo, useCallback, useState } from 'react';
import { StyledSelect } from './styles';

import { Props } from './types';

const Selector: FC<Props> = ({
  options,
  placeholder,
  value: defaultValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const handleChange = useCallback(
    (item: Props['options'][number]) => {
      if (onChange) {
        onChange(item);
      }
      setValue(item);
    },
    [onChange],
  );
  return (
    <StyledSelect
      components={{
        IndicatorSeparator: () => null,
      }}
      value={value}
      onChange={handleChange}
      isSearchable={false}
      options={options}
      classNamePrefix="react-select"
      {...props}
    />
  );
};

export default memo(Selector);
