import { FC } from 'react';
import { NegativeLogo, Title, Container, PositiveLogo } from './styles';
import { Props } from './types';

const FormPublisherLogo: FC<Props> = ({ inverse }) => {
  return (
    <Container>
      {inverse ? <NegativeLogo /> : <PositiveLogo />}
      <Title $inverse={inverse}>Form Publisher</Title>
    </Container>
  );
};

export default FormPublisherLogo;
