import styled from 'styled-components';
import DefaultAvatar from 'components/Avatar';
import DefaultDesktopTable from 'components/DesktopTable';
import addAlpha from 'utils/addAlpha';
import { Row } from 'components/DesktopTable/Rows/styles';
import { from } from 'styles/media';

export const Avatar = styled(DefaultAvatar)`
  margin-right: var(--avatarMargin);
  flex-shrink: 0;
`;

export const DesktopTable = styled(DefaultDesktopTable)`
  --horizontalPadding: 1rem;
  --avatarMargin: 0.75rem;
  --avatarSize: 2rem;

  ${Row} {
    padding: 0.5rem var(--horizontalPadding);
    position: relative;
    border-top: 0;

    &:hover {
      box-shadow: none;
    }

    &:not(:first-child):before {
      content: '';
      right: 0;
      position: absolute;
      top: -0.0625rem;
      width: calc(
        100% - var(--horizontalPadding) - var(--avatarSize) -
          var(--avatarMargin)
      );
      height: 1px;
      background-color: ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  ${from.tablet} {
    grid-column: 2;
  }
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.purple3};
  font-size: 1rem;
  margin-top: 0.875rem;

  ${from.tablet} {
    margin-top: 1.3125rem;
  }
`;
