import { useLayoutEffect, useMemo } from 'react';
import { CommonProps, OptionsType, OptionTypeBase } from 'react-select';
import { Props } from './types';

export const useSortDirection = ({
  getValue,
  options,
}: {
  getValue: CommonProps<OptionTypeBase, false>['getValue'];
  options: OptionsType<OptionTypeBase>;
}) => {
  const isDesc = useMemo(() => {
    let value = getValue();
    value = Array.isArray(value) ? value[0] : value;
    if (!value) return undefined;
    const currentOption = options.find(
      (option) => option.value === (value as OptionTypeBase).value,
    );
    if (!currentOption) return true;
    return currentOption.desc;
  }, [getValue, options]);
  return isDesc;
};

export const useAdjustSelectWidth = ({
  value,
  defaultValue,
}: Pick<Props, 'value' | 'defaultValue'>) => {
  useLayoutEffect(() => {
    const reactSelect = document.querySelector<HTMLDivElement>(
      '.react-select-sorter__control',
    );
    if (!reactSelect) return;
    reactSelect.style.width = `calc(${
      (value || defaultValue)?.label?.replaceAll(' ', '').length || 7
    }ch + 1rem)`;
  }, [value, defaultValue]);
};
