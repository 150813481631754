import firebase, { signInWithPopup } from '../useFirebase';

const MICROSOFT_PROVIDER_ID = 'microsoft.com';

async function loginWithMicrosoft() {
  const microsoftProvider = new firebase.auth.OAuthProvider(
    MICROSOFT_PROVIDER_ID,
  );
  microsoftProvider.setCustomParameters({ prompt: 'consent' });
  return signInWithPopup(microsoftProvider);
}

export default {
  loader: async () => {},
  initializer: async () => {},
  login: loginWithMicrosoft,
};
