import { useQuery, UseQueryOptions } from 'react-query';
import { getApproval, GetApprovalDto } from 'data/requests/approval';
import { Approval, normalizeApproval } from 'model/approval';

export function useApproval(
  {
    encodedCreatorKey,
    formId,
    responseId,
    encodedValidatorEmail,
    accessToken,
  }: Partial<GetApprovalDto>,
  options?: UseQueryOptions<Approval>,
) {
  const enabled = [
    encodedCreatorKey,
    formId,
    responseId,
    encodedValidatorEmail,
  ].every(Boolean);

  const { data, status } = useQuery<Approval>(
    [
      'approval',
      {
        encodedCreatorKey,
        formId,
        responseId,
        encodedValidatorEmail,
        accessToken,
      },
    ],
    getApproval,
    {
      enabled,
      ...options,
    },
  );

  const approval = data && normalizeApproval(data);

  return { approval, status };
}
