import { FC } from 'react';
import RegularForm from './RegularForm';
import RequestInfo from './RequestInfo';
import { Card } from './styles';
import { Props } from './types';

const ValidationForm: FC<Props> = ({
  filename,
  formType,
  imgSrc,
  needsSignature,
}) => {
  const handleFormRendering = () => {
    switch (formType) {
      case 'request-info':
        return (
          <RequestInfo
            filename={filename}
            imgSrc={imgSrc}
            needsSignature={needsSignature}
          />
        );
      default:
      case 'regular':
        return (
          <RegularForm
            filename={filename}
            imgSrc={imgSrc}
            needsSignature={needsSignature}
          />
        );
    }
  };
  return <Card>{handleFormRendering()}</Card>;
};

export default ValidationForm;
