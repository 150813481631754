import { Props } from './types';
import { Column } from './styles';
import { Cell } from '../styles';
import SortByButton from '../SortByButton';

const Columns = ({ headers, originalColumns }: Props) => {
  return (
    <>
      {headers.map((column, index, array) => {
        const isNotFirstOrLast = index !== 0 && index !== array.length - 1;
        const alignment = originalColumns[index].alignment ?? 'left';
        const { key, ...cellProps } = column.getHeaderProps();
        const { width: minWidth } = originalColumns[index];
        const { canSort, isSorted, isSortedDesc, getSortByToggleProps } =
          column;

        return (
          <Column key={key}>
            <Cell
              {...(isNotFirstOrLast && cellProps)}
              $alignment={alignment}
              $center={isNotFirstOrLast}
              style={{ minWidth }}
            >
              {column.render('Header')}
              {canSort && (
                <SortByButton
                  isRightAligned={alignment === 'right'}
                  isSorted={isSorted}
                  isSortedDesc={isSortedDesc}
                  {...getSortByToggleProps()}
                />
              )}
            </Cell>
          </Column>
        );
      })}
    </>
  );
};

export default Columns;
