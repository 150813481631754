export type Input = {
  creationTime: string;
  endTime: string;
  subscriptionId: string;
  paymentSource: 'STRIPE' | 'PAYPAL';
};

export const normalizeUserLicense = (input: Input) => {
  return { ...input } as const;
};

export type UserLicense = ReturnType<typeof normalizeUserLicense>;
