import { getDomain } from 'data/requests/domain';
import { Domain, normalizeDomain } from 'model/domain';
import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';

export const useDomain = (
  { userId, includeStats = true }: { userId?: string; includeStats?: boolean },
  options?: UseQueryOptions<Domain>,
) => {
  const { data, status } = useQuery(
    ['domain', { userId, includeStats }],
    getDomain,
    {
      ...options,
      enabled: !!userId,
    },
  );

  const normalizedDomain = useMemo(() => data && normalizeDomain(data), [data]);

  return {
    domain: normalizedDomain,
    status,
  };
};
