import styled, { css } from 'styled-components';

import { ReactComponent as DownArrow } from 'assets/images/icons/arrow-down-small.svg';

export const Container = styled(DownArrow)<{ $desc?: boolean }>`
  color: ${({ theme }) => theme.colors.purple4};
  transition: transform 200ms ease;
  ${({ $desc }) =>
    !$desc &&
    css`
      transform: rotate(180deg);
    `};
`;
