import { putSignature } from 'data/requests/signature';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export function useCreateOrUpdateSignature() {
  const { mutateAsync, isError } = useMutation(putSignature);

  const createOrUpdateSignature = useCallback(
    async ({
      userId,
      signatureAsDataString,
      accessToken,
    }: {
      userId: string;
      signatureAsDataString: string;
      accessToken: string;
    }) => {
      const base64Image = signatureAsDataString.split('base64,').pop() ?? '';
      await mutateAsync({
        userId,
        signatureImage: base64Image,
        accessToken,
      });
    },
    [mutateAsync],
  );

  return { createOrUpdateSignature, isError };
}
