import StatsCard from 'components/StatsCard';
import { from, useMediaQuery } from 'styles/media';

import { useConnect } from './connect';
import { Container, Title, StatsContainer, Header } from './styles';
import List from './List';

const Dashboard = () => {
  const { forms, isLoading, stats } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);

  return (
    <>
      <Container>
        <Header>
          <Title>Dashboard</Title>
        </Header>
        <StatsContainer>
          <StatsCard
            variant="forms"
            number={forms?.length}
            text="Forms created all time"
          />
          <StatsCard
            variant="files30"
            number={stats?.totalGeneratedThisMonth}
            text="Files generated this month"
          />
          <StatsCard
            variant="files"
            number={stats?.totalFilesGenerated}
            text="Files generated all time"
          />
        </StatsContainer>
        <List isLoading={isLoading} isMobile={isMobile} forms={forms} />
      </Container>
    </>
  );
};

export default Dashboard;
