export function safeDateFormat(
  formatFunction: (date: number | Date) => string,
  date: number | Date,
) {
  let formattedDate: string;
  try {
    formattedDate = formatFunction(date);
  } catch {
    formattedDate = '';
  }
  return formattedDate;
}
