import styled, { keyframes } from 'styled-components';
import { from } from 'styles/media';
import DefaultSignatureInput from 'components/SignatureInput';
import DefaultTextarea from 'components/Textarea';
import { Form as DefaultForm } from 'formik';

export const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(1.25rem);
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 20rem;
  border-radius: 0.625rem;
  animation: slideUp 0.5s ease-out forwards;
  position: relative;
  margin: 2rem auto;
  padding: 2rem;
  width: 100%;
  max-width: 35rem;

  ${from.mobile} {
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.purple4};
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  text-align: center;
  margin-bottom: 2.5rem;
  animation: slideUp 0.5s ease-out forwards;
`;

export const Form = styled(DefaultForm)`
  width: 100%;
`;
export const ButtonsRow = styled.div`
  display: flex;
  margin-bottom: -0.5rem;
  flex-wrap: wrap;

  > button {
    margin-bottom: 0.5rem;
  }

  ${from.tablet} {
    flex-wrap: nowrap;

    > :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;

export const SignatureInput = styled(DefaultSignatureInput)`
  margin-bottom: 1.5rem;
`;

export const Textarea = styled(DefaultTextarea)`
  margin-bottom: 1.5rem;
`;
