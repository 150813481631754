/* eslint-disable import/no-webpack-loader-syntax */
import { memo } from 'react';
import { ExporterComponent } from 'components/DesktopTable/types';
import { Form } from 'model/form';
import ExportToCsv from 'components/ExportToCsv';
import transformerWorkerUrl from 'worker-plugin/loader?name=forms!../transformer';
import testingVar from 'utils/testingVar';
import { useWorker } from 'utils/useWorker';
import { FormsWorker } from '../transformer';

const Exporter: ExporterComponent<Form[]> = ({ data }) => {
  const { worker: transformer } = useWorker<FormsWorker>(
    transformerWorkerUrl,
    'FormsTransformer',
  );
  return (
    <ExportToCsv
      data-test-id={testingVar('submit-export')}
      data={data}
      transformer={transformer}
      fileName={`Exported_Forms_${new Date().toDateString()}`}
    />
  );
};

export default memo(Exporter);
