/* eslint-disable import/no-webpack-loader-syntax */
import refreshWorkerUrl from 'worker-plugin/loader?name=refresh!./refresh-worker';
import { useWorker } from 'utils/useWorker';
import { useRef } from 'react';
import { proxy } from 'comlink';
import { RefreshWorker } from './refresh-worker';
import { TokenQuery } from './token';
import { Google } from './google-auth';

export function useSessionRefresher() {
  const refreshId = useRef<number | undefined>(undefined);
  const { worker: refreshWorker } = useWorker<RefreshWorker>(
    refreshWorkerUrl,
    'RefreshSessionWorker',
  );

  /**
   * this interval is responsible of refreshing the session every time the
   * session is about to expire on google's side
   */
  async function triggerRefreshSessionTimeout(data: TokenQuery) {
    if (!data?.expiresAt || refreshId.current !== undefined) return;
    const refreshInterval = data.expiresAt - Date.now() - 2000;
    refreshId.current = await refreshWorker.setRefreshInterval(
      refreshInterval,
      proxy(() => {
        Google.refreshAuth();
      }),
    );
  }

  function clearRefreshValue() {
    refreshId.current = undefined;
  }
  return { refreshWorker, clearRefreshValue, triggerRefreshSessionTimeout };
}
