import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { up } from 'utils/Paths';
import { Location } from 'history';
import { useMe } from 'data/hooks/useMe';
import { useCustomerPortal } from 'data/hooks/useCustomerPortal';
import { CANCEL_PLAN_PAYPAL_LINK } from 'components/CurrentPlan/constants';

export const useConnect = () => {
  const { push, goBack, replace } = useHistory();
  const { pathname, state } = useLocation<{ background?: Location }>();
  const match = !!useRouteMatch('/subscription');
  const { me } = useMe();
  const doesHavePortal =
    me &&
    ('stripeCustomerId' in me ||
      ('license' in me && me.license.paymentSource === 'STRIPE'));
  const { customerPortal, status: portalLinkStatus } = useCustomerPortal({
    userId: doesHavePortal && match ? me?.id : undefined,
    returnUrl: window.location.origin,
  });
  const backgroundRef = useRef<typeof state['background']>();

  useEffect(() => {
    if (state?.background) {
      const cleanState = { ...state };
      backgroundRef.current = cleanState.background;
      delete cleanState.background;
      replace(pathname, cleanState);
    }
  }, [state, replace, pathname]);

  const onClose = useCallback(() => {
    if (backgroundRef.current) {
      backgroundRef.current = undefined;
      goBack();
    } else {
      push(up(pathname));
    }
  }, [goBack, push, pathname]);

  const cancelPlanId = me?.license?.subscriptionId;

  const { type, quota, users } = (() => {
    if (me?.plan === 'PAID') {
      return {
        type: 'Individual account',
        quota: 'Unlimited form submissions or spreadsheet rows / month',
        users: '1 user',
      };
    }

    if (me?.plan === 'DOMAIN_PAID') {
      return {
        type: 'Business account',
        quota: 'Unlimited form submissions or spreadsheet rows / month',
        users: 'Unlimited users',
      };
    }

    return {
      type: 'Free account',
      quota: '20 form submissions or spreadsheet rows / month',
      users: '1 user',
    };
  })();

  const accountId = (() => {
    switch (me?.plan) {
      case 'DOMAIN_PAID':
        return me.email.split('@')[1];
      default:
        return me?.email;
    }
  })();

  const managePlanLink =
    cancelPlanId && me?.license.paymentSource === 'PAYPAL'
      ? CANCEL_PLAN_PAYPAL_LINK
      : customerPortal?.stripeCustomerPortalUrl;

  return {
    me,
    accountId,
    accountType: type,
    quota,
    users,
    managePlanLink,
    portalLinkStatus,
    isOpen: match,
    handle: {
      close: onClose,
    },
  };
};
