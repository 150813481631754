import styled, { css } from 'styled-components';
import { ReactComponent as DownArrow } from 'assets/images/icons/arrow-down-small.svg';

export const Container = styled.button<{
  $isSorted: boolean;
  $isRightAligned: boolean;
}>`
  appearance: none;
  background: none;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.purple4};
  margin-left: 0.375rem;
  opacity: 0;
  transition: opacity 200ms ease;
  z-index: 1;

  &:focus,
  &:hover {
    opacity: 1;
  }

  ${({ $isRightAligned }) =>
    $isRightAligned &&
    css`
      position: absolute;
      right: -1.75rem;
    `};

  ${({ $isSorted }) =>
    $isSorted
      ? css`
          opacity: 1;
        `
      : css`
          > svg {
            transform: translateY(-0.25rem);

            &:first-child {
              transform: translateY(0.25rem) rotate(180deg);
            }
          }
        `};
`;

export const DownIcon = styled(DownArrow)``;

export const UpIcon = styled(DownArrow)`
  transform: rotate(180deg);
`;
