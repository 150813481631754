import Button from 'components/Button';
import styled from 'styled-components';
import { ReactComponent as Download } from 'assets/images/icons/download.svg';
import DefaultLoader from 'components/Loader';
import { from } from 'styles/media';

export const Container = styled(Button).attrs(() => ({
  variant: 'outline',
  size: 'big',
}))`
  border-color: transparent;

  &:focus {
    transition-property: color;
    outline-offset: 4px;
    outline: 2px auto;
  }

  ${from.tablet} {
    border-color: ${({ theme }) => theme.colors.purple2};
  }
`;

export const DownloadIcon = styled(Download)`
  margin-right: 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
`;

export const Loader = styled(DefaultLoader)`
  padding: 0;
  margin-right: 0.625rem;
  display: flex;
`;
