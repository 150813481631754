import { FC, memo } from 'react';
import testingVar from 'utils/testingVar';
// import { useLocation } from 'react-router';
import { Props } from './types';
import {
  AccountDropdown,
  Container,
  Content,
  MenuButton,
  MenuIcon,
  AccountPlaceholder,
  // Link,
  Help,
  HelpIcon,
} from './styles';

const TopBar: FC<Props> = ({
  name,
  photo,
  email,
  initials,
  plan,
  openMenu,
  onLogout,
  onAccountSwitch,
  skipPlanChange,
  ...props
}) => {
  // const { pathname } = useLocation();
  // const shouldShowUpgradePlanButton =
  //   plan === 'FREE' && !pathname.startsWith('/settings/billing');
  return (
    <Container {...props}>
      {openMenu && (
        <MenuButton data-test-id={testingVar('submit-open')} onClick={openMenu}>
          <MenuIcon />
        </MenuButton>
      )}
      <Content />
      {/* removed for V1
      {shouldShowUpgradePlanButton && (
        <Link to="/settings/billing" data-test-id="upgrade-plan">
          Upgrade plan
        </Link>
      )} */}
      <Help
        href="https://support.form-publisher.com/hc/en-us"
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpIcon />
      </Help>
      {name && email ? (
        <AccountDropdown
          {...{
            name,
            photo,
            email,
            initials,
            onLogout,
            onAccountSwitch,
            skipPlanChange,
          }}
        />
      ) : (
        <div>
          <AccountPlaceholder />
        </div>
      )}
    </Container>
  );
};

export default memo(TopBar);
