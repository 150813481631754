import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Root from 'containers/Root';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/themes';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'data/queryClient';
import ErrorBoundary from 'containers/Common/ErrorBoundary';
import { LazyMotion, domAnimation } from 'framer-motion';

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <LazyMotion strict features={domAnimation}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ErrorBoundary>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </LazyMotion>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
