import styled from 'styled-components';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  padding: 0.75rem 1rem;

  ${from.tablet} {
    padding: 1.25rem 2rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 5rem);

  ${from.tablet} {
    padding: 0 2rem;
  }
`;
