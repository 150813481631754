import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Container = styled.div`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.purple2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

export const BaseButton = css`
  appearance: none;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 2rem;
  height: 3rem;
  cursor: pointer;
  transform: translateY(-0.0625rem);

  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.purple4};
  }
`;

export const Button = styled.button`
  ${BaseButton};
  color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};

  > svg {
    width: 1rem;
    height: 1rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PageButton = styled.button<{ $active: boolean }>`
  ${BaseButton};
  color: ${({ theme }) => theme.colors.purple3};
  border-top: 0.0938rem solid transparent;

  ${({ theme, $active }) =>
    $active &&
    css`
      border-top: 0.0938rem solid ${theme.colors.orange};
      color: ${theme.colors.purple4};
      font-weight: ${theme.weights.medium};
    `};
`;
