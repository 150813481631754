import { client } from 'data/client';
import { Input } from 'model/form';
import { QueryFunctionContext } from 'react-query';

export type GetFormsDto = Partial<{
  limit: number;
  offset: number;
  'lastTimeUsed[gte]': string;
  'lastTimeUsed[lte]': string;
  fields: keyof Input;
}>;

type GetFormsResponse = {
  items: Input[];
};

function denormalizeParams(params?: GetFormsDto) {
  const denormalizedParams = { ...params };
  if (params && 'lastTimeUsed[gte]' in params && params['lastTimeUsed[gte]']) {
    denormalizedParams['lastTimeUsed[gte]'] = new Date(
      params['lastTimeUsed[gte]'],
    ).toISOString();
  }
  if (params && 'lastTimeUsed[lte]' in params && params['lastTimeUsed[lte]']) {
    denormalizedParams['lastTimeUsed[lte]'] = new Date(
      params['lastTimeUsed[lte]'],
    ).toISOString();
  }
  return denormalizedParams;
}

export async function getForms({ queryKey }: QueryFunctionContext) {
  const [, { userId, params }] = queryKey as [
    string,
    { userId: string; params?: GetFormsDto },
  ];
  const denormalizedParams = denormalizeParams(params);
  const { data } = await client.get<GetFormsResponse>(
    `/users/${userId}/forms`,
    { params: denormalizedParams },
  );
  return data.items;
}

export async function getForm({ queryKey }: QueryFunctionContext) {
  const [, { userId, formId }] = queryKey as [
    string,
    { userId: string; formId: string },
  ];
  const { data } = await client.get(`/users/${userId}/form/${formId}`);
  return data;
}
