import { FC, useState } from 'react';
import { from, useMediaQuery } from 'styles/media';
import MobileMenu from 'components/MobileMenu';
import DesktopMenu from 'components/DesktopMenu';
import TopBar from 'components/TopBar';
import { Props } from './types';
import { Container, Main } from './styles';

const Layout: FC<Props> = ({
  initials,
  name,
  photo,
  email,
  onLogout,
  onAccountSwitch,
  children,
  plan,
  cannon,
  ...props
}) => {
  const isMobile = !useMediaQuery(from.tablet);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  if (isMobile) {
    return (
      <Container {...props}>
        <MobileMenu
          open={isMenuOpen}
          closeMenu={closeMenu}
          cannon={cannon}
          canAccessTeamReport={plan === 'DOMAIN_PAID'}
        />
        <TopBar
          openMenu={openMenu}
          {...{ initials, name, photo, email, plan, onAccountSwitch, onLogout }}
        />
        {children}
      </Container>
    );
  }

  return (
    <Container {...props}>
      <DesktopMenu
        canAccessTeamReport={plan === 'DOMAIN_PAID'}
        cannon={cannon}
      />
      <Main>
        <TopBar
          {...{ initials, name, photo, email, plan, onAccountSwitch, onLogout }}
        />
        {children}
      </Main>
    </Container>
  );
};

export default Layout;
