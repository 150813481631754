import { FC } from 'react';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings.svg';
import { ReactComponent as UpgradeIcon } from 'assets/images/icons/upgrade.svg';

import Modal from 'components/Modal';
import type { CustomerInput as User } from 'model/user';
import type { UserLicense as License } from 'model/userLicense';

import {
  AccountHeader,
  Domain,
  IconWrapper,
  Link,
  LinkIcon,
  Title,
  EmailsLimit,
  Placeholder,
  IconPlaceholder,
  LinkIconPlaceholder,
  TextPlaceholder,
} from './styles';
import { useConnect } from './connect';
import { Props } from './types';
import RenewSubscriptionLink from './RenewSubscriptionLink';

const PlaceholderLink = () => (
  <Placeholder>
    <IconWrapper>
      <IconPlaceholder />
    </IconWrapper>
    <TextPlaceholder />
    <LinkIconPlaceholder />
  </Placeholder>
);

const isStripeCustomer = (user: User): boolean => {
  return (
    user.licence?.paymentSource?.toUpperCase() === 'STRIPE' ||
    !!user.stripeCustomerId
  );
};

const isLicenseActive = (license: License): boolean => {
  const currentTime = Date.now();
  const licenseEndTime = new Date(license.endTime).getTime();
  return licenseEndTime > currentTime;
};

const Subscription: FC<Props> = () => {
  const {
    me,
    accountId,
    accountType,
    quota,
    users,
    managePlanLink,
    portalLinkStatus,
    handle,
    isOpen,
  } = useConnect();

  let link: JSX.Element | null = <PlaceholderLink />;

  if (me?.plan === 'FREE') {
    link = (
      <Link
        href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconWrapper>
          <UpgradeIcon />
        </IconWrapper>
        Upgrade plan
        <LinkIcon />
      </Link>
    );
  } else if (portalLinkStatus === 'loading') {
    link = <PlaceholderLink />;
  } else if (managePlanLink !== undefined) {
    if (!me?.license) {
      link = null;
    } else if (
      ['CANCELLED', 'CANCELED'].includes(
        me?.license?.subscriptionStatus?.toUpperCase(),
      )
    ) {
      link = (
        <RenewSubscriptionLink
          isStripeCustomer={isStripeCustomer(me)}
          hasActiveLicense={isLicenseActive(me.license)}
          managePlanLink={managePlanLink}
        />
      );
    } else {
      link = (
        <Link href={managePlanLink} target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <SettingsIcon />
          </IconWrapper>
          Manage plan
          <LinkIcon />
        </Link>
      );
    }
  } else {
    link = null;
  }

  return (
    <Modal title="Subscription plan" open={isOpen} onClose={handle.close}>
      <AccountHeader>
        <Title>{accountType}</Title>
        <Domain>{accountId}</Domain>
        <EmailsLimit>
          <div>{quota}</div>
          <div>{users}</div>
        </EmailsLimit>
      </AccountHeader>
      {link}
    </Modal>
  );
};

export default Subscription;
