import styled, { css } from 'styled-components';

import addAlpha from 'utils/addAlpha';
import { Props } from './types';

export const TextWrapper = styled.span`
  align-items: center;
  display: flex;
`;

export const Button = styled.button<{
  $variant: Props['variant'];
  $size: Props['size'];
  $iconPosition: Props['iconPosition'];
}>`
  border-radius: 0.25rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  justify-content: center;
  line-height: 1.375rem;
  padding: 0.25rem 1rem;
  transition: all 0.2s ease;

  :disabled {
    cursor: not-allowed;
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
      default:
        return css`
          background-color: ${({ theme }) => theme.colors.orange};
          color: ${({ theme }) => theme.colors.purple4};

          :focus {
            outline: none;
          }

          :disabled {
            background-color: ${({ theme }) => theme.colors.gray325};
            color: ${({ theme }) => theme.colors.white};
          }
        `;

      case 'secondary':
        return css`
          background: none;
          border-color: ${({ theme }) => theme.colors.purple};
          color: ${({ theme }) => theme.colors.purple};

          :hover:not(:disabled) {
            background-color: ${({ theme }) => theme.colors.purple};
            color: ${({ theme }) => theme.colors.white};
          }

          :focus {
            background-color: ${({ theme }) => theme.colors.purple};
            color: ${({ theme }) => theme.colors.white};
            outline: none;
          }

          :disabled {
            border-color: ${({ theme }) => theme.colors.gray325};
            background-color: ${({ theme }) => theme.colors.gray325};
            color: ${({ theme }) => theme.colors.white};
          }
        `;

      case 'link':
        return css`
          background: none;
          border: none;
          border-bottom: 0.0625rem solid
            ${({ theme }) => addAlpha(theme.colors.purple3, 0.25)};
          border-radius: 0;
          color: ${({ theme }) => theme.colors.purple3};
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          padding: 0 0.125rem 0.125rem;

          :hover:not(:disabled) {
            border-color: ${({ theme }) => theme.colors.purple3};
            color: ${({ theme }) => theme.colors.purple3};
          }

          :focus {
            border-color: ${({ theme }) => theme.colors.purple4};
            color: ${({ theme }) => theme.colors.purple4};
            outline: none;
          }

          :disabled {
            border-color: ${({ theme }) =>
              addAlpha(theme.colors.purple3, 0.25)};
            color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.25)};
          }
        `;

      case 'outline':
        return css`
          background: none;
          border-color: ${({ theme }) => theme.colors.purple2};
          color: ${({ theme }) => theme.colors.purple4};

          :hover:not(:disabled) {
            border-color: ${({ theme }) => theme.colors.purple4};
            color: ${({ theme }) => theme.colors.purple4};
          }

          :focus {
            border-color: ${({ theme }) => theme.colors.purple4};
            color: ${({ theme }) => theme.colors.purple4};
            outline: none;
          }

          :disabled {
            border-color: ${({ theme }) => theme.colors.purple2};
            color: ${({ theme }) => theme.colors.gray350};
          }
        `;
    }
  }}

  ${({ $size }) =>
    $size === 'big' &&
    css`
      border-radius: 0.3125rem;
      padding: 0.5rem 1rem;

      ${TextWrapper} {
        position: relative;
        top: 0.0625rem;
      }
    `}

  ${({ $iconPosition }) =>
    $iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const IconWrapper = styled.span<{
  $position: Props['iconPosition'];
}>`
  display: inline-flex;
  margin-right: 0.625rem;
  width: 1.25rem;

  ${({ $position }) =>
    $position === 'right' &&
    css`
      margin-left: 0.625rem;
      margin-right: 0;
    `}
`;
