import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { from } from 'styles/media';

import addAlpha from 'utils/addAlpha';
import { Props } from './types';

export const Tab = styled(NavLink).attrs(() => ({
  activeClassName: 'selected',
}))`
  border-bottom: 0.0938rem solid transparent;
  color: ${({ theme }) => theme.colors.purple3};
  display: inline-block;
  margin-right: 2rem;
  margin: 0 1rem -0.0625rem;
  padding: 0.6875rem 0.25rem;
  text-decoration: none;
  transition: color 0.2s ease;

  :hover {
    color: ${({ theme }) => theme.colors.purple4};
  }

  :focus {
    outline-offset: 0;
  }

  &.selected {
    color: ${({ theme }) => theme.colors.purple4};
    border-bottom-color: ${({ theme }) => theme.colors.orange};
    text-shadow: 0.0313rem 0 0 currentColor;
  }
`;

export const TabsContainer = styled.nav<{
  $size: Props['size'];
}>`
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
  font-size: 1rem;
  line-height: 1.625rem;
  width: 100%;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      margin: 0 0 1.875rem;
      ${from.mobile} {
        margin: 0 0 1.875rem;
      }
      ${Tab} {
        padding: 0.6875rem 0;
        :first-child {
          margin-left: 0;
        }
        &.selected {
          border-bottom-color: ${({ theme }) => theme.colors.purple4};
        }
      }
    `}
`;
