import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';

const ListOfFormsEmpty = () => {
  return (
    <>
      <Title>Nothing to see here...</Title>
      <Subtitle>
        You don’t have any forms generated. Learn how to{' '}
        <a
          href="https://support.form-publisher.com/hc/en-us/categories/201581669-Getting-Started"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get started
        </a>
        .
      </Subtitle>
    </>
  );
};

export default ListOfFormsEmpty;
