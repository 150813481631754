import { memo } from 'react';
import useLogic from './logic';
import {
  Component,
  ComponentContainer,
  Container,
  Error,
  ErrorIcon,
  Label,
  Optional,
} from './styles';
import { Props } from './types';

const Textarea = ({
  className,
  isOptional,
  addonMessage,
  label,
  name,
  placeholder,
  ...props
}: Props) => {
  const { error, hasError, onBlur, onChange, value } = useLogic({ name });

  return (
    <Container className={className}>
      <Label htmlFor={name}>
        {label}{' '}
        {isOptional || addonMessage ? (
          <Optional>({addonMessage ?? 'optional'})</Optional>
        ) : null}
      </Label>
      <ComponentContainer>
        <Component
          placeholder={placeholder}
          $hasError={hasError}
          $hasValue={!!value}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          {...props}
        />
        {hasError ? <ErrorIcon /> : null}
      </ComponentContainer>
      <Error>{hasError ? error : null}</Error>
    </Container>
  );
};

export default memo(Textarea);
