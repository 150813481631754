import styled from 'styled-components';
import { ReactComponent as FullStarSvg } from 'assets/images/icons/full-star.svg';
import { ReactComponent as ZeroEightStarSvg } from 'assets/images/icons/zero-eight-star.svg';

export const ReviewsContainer = styled.div`
  background: linear-gradient(180deg, #463e72 0%, #332c5c 100%);
  border-radius: 6.25rem;
  padding: 0.75rem 0.9375rem;
  display: flex;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
`;

export const StarsContainer = styled.div`
  margin-right: 0.625rem;
  display: flex;
  width: 6.25rem;
  height: 1.25rem;
  justify-content: space-between;
  & > svg {
    margin-top: 0.125rem;
  }
`;

export const ReviewText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1rem;
  display: flex;
  align-items: center;

  color: #ffffff;
`;

export const DotStyle = styled.span`
  color: #032533;
`;

export const FullStar = styled(FullStarSvg)`
  width: 0.9375rem;
  height: 0.9375rem;
`;

export const ZeroEightStar = styled(ZeroEightStarSvg)`
  width: 0.9375rem;
  height: 0.9375rem;
`;
