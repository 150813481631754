import { FC, memo } from 'react';
import testingVar from 'utils/testingVar';
import { Container, DownIcon, UpIcon } from './styles';
import { Props } from './types';
import { useSortDirection } from '../logic';

const Option: FC<Props> = ({
  children,
  className,
  innerProps,
  innerRef,
  isSelected,
  getValue,
  options,
}) => {
  const isDesc = useSortDirection({ getValue, options });
  const { onMouseMove, onMouseOver, ...rest } = innerProps;
  return (
    <Container
      className={className}
      data-test-id={testingVar('sort-dropdown-option')}
      isSelected={isSelected}
      ref={innerRef}
      {...rest}
    >
      <span>{children}</span>
      {isSelected && (isDesc ? <DownIcon /> : <UpIcon />)}
    </Container>
  );
};

export default memo(Option);
