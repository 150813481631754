import { postApproval, UpdateApprovalRequest } from 'data/requests/approval';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export function useUpdateApproval() {
  const { mutateAsync, isError } = useMutation(postApproval);

  const updateApproval = useCallback(
    async (updateApprovalRequestDto: UpdateApprovalRequest) => {
      await mutateAsync(updateApprovalRequestDto);
    },
    [mutateAsync],
  );

  return { updateApproval, isError };
}
