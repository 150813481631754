import { queryClient } from 'data/queryClient';
import { useQuery } from 'react-query';

export type GoogleProfileQuery = {
  name: string;
  email: string;
  imageUrl: string;
};

async function getGoogleProfile() {
  return queryClient.getQueryData<GoogleProfileQuery>('googleProfile');
}

export const useGoogleProfile = () => {
  const { data } = useQuery('googleProfile', getGoogleProfile, {
    cacheTime: Infinity,
  });
  return { data };
};
