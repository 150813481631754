import Button from 'components/Button';
import styled from 'styled-components';

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.purple3};
  font-size: 0.75rem;
  letter-spacing: -0.0033em;
  line-height: 1.0625rem;
  margin: 1.5rem auto 0;
  max-width: 20rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.purple4};
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.orange};
  font-weight: ${({ theme }) => theme.weights.medium};
  margin-top: 1rem;
`;

export const LoginMessage = styled.p`
  margin-bottom: 3rem;
`;

export const ConfirmButton = styled(Button)`
  height: 2.5rem;
  display: flex;
  align-items: center;
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
