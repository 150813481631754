export type Input = {
  userId: string;
  signatureUrl: string;
  signatureAsDataString: string;
};

export const normalizeSignature = (input: Input) => {
  return { ...input };
};

export type Signature = ReturnType<typeof normalizeSignature>;
