/**
 * The purpose of this factory is to provide a "public" api to
 * interact with the multiple social platforms that we are going
 * to allow for performing login on validation workflow
 */

import { useCallback, useState } from 'react';
import { useAuth } from 'data/context/AuthContext';
import type {
  SocialLoginPlatform,
  SocialLoginLoader,
  SocialLoginLoginCallback,
} from './types';
import { Facebook, Google, Microsoft, Twitter } from './providers';
import { ErrorCodes, useFirebase, logout as socialLogout } from './useFirebase';

const loadedPlatforms: Record<SocialLoginPlatform, boolean> = {
  google: false,
  twitter: false,
  facebook: false,
  microsoft: false,
};

export async function socialLoginFactory(platform: SocialLoginPlatform) {
  const loaders: Record<SocialLoginPlatform, SocialLoginLoader> = {
    google: Google.loader,
    facebook: Facebook.loader,
    twitter: Twitter.loader,
    microsoft: Microsoft.loader,
  };

  const loginHandlers: Record<SocialLoginPlatform, SocialLoginLoginCallback> = {
    google: Google.login,
    facebook: Facebook.login,
    twitter: Twitter.login,
    microsoft: Microsoft.login,
  };

  if (!loadedPlatforms[platform]) {
    await loaders[platform]();
    loadedPlatforms[platform] = true;
  }
  return loginHandlers[platform]();
}

function normalizeError(error: Error): ErrorCodes {
  const code = error.name;
  switch (code) {
    case ErrorCodes.POPUP_CLOSED_ERROR_CODE:
      return ErrorCodes.POPUP_CLOSED_ERROR_CODE;
    case ErrorCodes.POPUPS_BLOCKED_BY_BROWSER:
      return ErrorCodes.POPUPS_BLOCKED_BY_BROWSER;
    default:
      return ErrorCodes.UNKNOWN;
  }
}

export function useSocialLogin() {
  const [areCookiesDisabled, setAreCookiesDisabled] = useState<boolean>(false);
  const [allInitialDataReceived, setAllInitialDataReceived] =
    useState<boolean>();
  const [socialLoginUserData, setSocialLoginUserData] = useState(null);
  const initialLoginStateListener = (data: any) => {
    setAllInitialDataReceived(
      data.initialGoogleUserDataReceived && data.initialUserDataReceived,
    );
    if (data.userData) {
      setSocialLoginUserData(data.userData);
    }
  };

  const errorListener = (error: any) => {
    if (error.error === ErrorCodes.COOKIES_BLOCKED) {
      setAreCookiesDisabled(true);
    }
  };

  useFirebase(initialLoginStateListener, errorListener, Google);

  const [credentials, setCredentials] =
    useState<{ email: string; accessToken: string }>();

  const logout = async () => {
    socialLogout();
    await Google.logout();
    window.location.reload();
  };

  const [errors, setErrors] = useState<Record<SocialLoginPlatform, any>>({
    google: null,
    facebook: null,
    twitter: null,
    microsoft: null,
  });

  const [loaders, setLoaders] = useState<Record<SocialLoginPlatform, boolean>>({
    google: false,
    facebook: false,
    twitter: false,
    microsoft: false,
  });
  const { loading } = useAuth();

  const setLoading = useCallback(
    (platform: SocialLoginPlatform, status: boolean) =>
      setLoaders((prev) => ({ ...prev, [platform]: status })),
    [],
  );

  const login = useCallback(
    async (platform: SocialLoginPlatform) => {
      try {
        setLoading(platform, true);
        const { email, token } = await socialLoginFactory(platform);
        const localCredentials = { email, accessToken: token };
        setCredentials(localCredentials);
        return { error: undefined, credentials: localCredentials };
      } catch (error) {
        // actual error codes will be lost from here
        // if you want to log firebase's rejection error
        // make sure to use `error` instead of `normalizedError`
        // e.g: log(error.name)
        const normalizedError = normalizeError(error as Error);
        if (normalizedError !== ErrorCodes.POPUP_CLOSED_ERROR_CODE) {
          setErrors((prev) => ({ ...prev, [platform]: normalizedError }));
        }
        return { error: normalizedError, credentials: undefined };
      } finally {
        setLoading(platform, false);
      }
    },
    [setLoading],
  );

  const isUserLoggedIn = useCallback(() => {
    if (allInitialDataReceived) {
      const userData: any = Google.check() || socialLoginUserData;
      if (userData && userData.email && credentials?.email !== userData.email) {
        const localCredentials = {
          email: userData.email || '',
          accessToken: userData.token || '',
        };
        setCredentials(localCredentials);
      }
      return !!userData;
    }
    return undefined;
  }, [allInitialDataReceived, credentials, socialLoginUserData]);

  return {
    credentials: {
      ...credentials,
      email: (credentials?.email || '').toLowerCase(),
    },
    errors,
    loaders: {
      ...loaders,
      google: loading,
    },
    login,
    isUserLoggedIn,
    areCookiesDisabled,
    logout,
  };
}
