import { addDays } from 'date-fns';
import { getRootHost } from 'utils/getRootHost';

export const getCookie = (name: string): string | null => {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');

  for (let i = 0; i < cookiesArray.length; i += 1) {
    const cookie = cookiesArray[i].trim();

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }

  return null;
};

export const getAuthUserIndex = () => {
  return Number(getCookie('G_AUTHUSER_H')) || 0;
};

export const CookiesOptions = {
  domain: getRootHost(window.location.host),
  path: '/',
  expires: addDays(new Date(), 730),
  secure: true,
};
