import { FC, memo } from 'react';
import { ReactComponent as Dashboard } from 'assets/images/icons/list.svg';
// import { ReactComponent as Team } from 'assets/images/icons/team.svg';
import Logo from 'components/Logo';
// import testingVar from 'utils/testingVar';
import {
  Container,
  Header,
  Navigation,
  Item,
  ItemIcon,
  TopSection,
  // BottomSection,
} from './styles';
import { Props } from './types';
// import SmallCannonNavBanner from '../SmallCannonNavBanner';

const DesktopMenu: FC<Props> = ({ canAccessTeamReport, cannon, ...props }) => {
  return (
    <Container {...props}>
      <TopSection>
        <Header>
          <Logo inverse />
        </Header>
        <Navigation>
          <Item to="/dashboard">
            <ItemIcon as={Dashboard} />
            Dashboard
          </Item>
          {/* removed for V1
          {canAccessTeamReport && (
            <Item
              data-test-id={testingVar('view-team-report')}
              to="/team-report"
            >
              <ItemIcon as={Team} />
              Team report
            </Item>
          )} */}
        </Navigation>
      </TopSection>
      {/* Enable this whenever it's necessary */}
      {/* <BottomSection>
        {cannon && cannon.enabled && (
          <SmallCannonNavBanner cannon={cannon} />
        )}
      </BottomSection> */}
    </Container>
  );
};

export default memo(DesktopMenu);
