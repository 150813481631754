import { Token } from 'data/context/AuthContext/token';
import { Google } from 'data/context/AuthContext/google-auth';
import { queryClient } from 'data/queryClient';
import { AuthCookie } from 'utils/authCookies';

function listen(callback: any) {
  callback(true);
}

function checkUserLoggedIn() {
  const { token } = Token.get();
  const userData = Google.getUserData();

  if (token && userData && userData.email) {
    return {
      email: userData.email,
      token,
    };
  }

  return false;
}

async function logout() {
  await Google.signOut();
  Token.clear();
  queryClient.clear();
  AuthCookie.remove();
}

async function loadGoogleApi() {
  return Promise.resolve();
}

async function loginWithGoogle() {
  return Promise.resolve(
    checkUserLoggedIn() as { email: string; token: string },
  );
}

export default {
  loader: loadGoogleApi, // Need a resolved promise to not change existing connector logic
  initializer: () => {}, // Not needed for the GIS
  login: loginWithGoogle, // Never used for GIS
  check: checkUserLoggedIn,
  listen,
  logout,
};
