import testingVar from 'utils/testingVar';
import { Title, Text, WarningIcon, Button } from '../styles';

const ErrorCard = () => {
  // we do a hard reload to make sure any corrupted state is cleared
  // all in all, let's start over
  const reloadPage = () => window.location.reload();
  return (
    <>
      <WarningIcon />
      <Title data-test-id={testingVar('validation-workflow-error')}>
        Sorry, an error ocurred
      </Title>
      <Text>
        Please try again or visit our{' '}
        <a href={process.env.REACT_APP_HELP_CENTER_URL}>Help center</a>
      </Text>
      <Button onClick={reloadPage}>Try again</Button>
    </>
  );
};

export default ErrorCard;
