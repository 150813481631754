import styled, { css } from 'styled-components';
import DefaultLoader from 'components/Loader';
import { ButtonProps, IconProps } from './types';

export const TextWrapper = styled.span`
  align-items: center;
  display: flex;
`;

export const Loader = styled(DefaultLoader)`
  display: flex;

  && > div {
    color: inherit;
  }
`;

export const Button = styled.button<ButtonProps>`
  padding: 0;
  align-items: center;
  background-color: transparent;
  border-radius: 0.3rem;
  border: 0.0625rem solid;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  height: 2.5rem;
  justify-content: center;
  letter-spacing: -0.66%;
  line-height: 1rem;
  min-width: 8.5rem;
  transition: all 0.2s ease;
  white-space: nowrap;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};

  :disabled {
    cursor: not-allowed;
  }

  ${({ $color, $variant }) => {
    switch ($color) {
      case 'primary':
      default:
        return $variant === 'outlined'
          ? css`
              border-color: ${({ theme }) => theme.colors.red600};
              color: ${({ theme }) => theme.colors.red500};

              &:hover:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.red400};
                color: ${({ theme }) => theme.colors.red400};
              }
              &:active:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.red600};
                color: ${({ theme }) => theme.colors.red600};
              }
            `
          : css`
              background-color: ${({ theme }) => theme.colors.red500};
              color: ${({ theme }) => theme.colors.white};

              :disabled {
                background-color: ${({ theme }) => theme.colors.gray325};
              }

              &:hover:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.red400};
              }
              &:active:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.red600};
              }
            `;
      case 'secondary':
        return $variant === 'outlined'
          ? css`
              border-color: ${({ theme }) => theme.colors.green600};
              color: ${({ theme }) => theme.colors.green500};

              &:hover:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.green400};
                color: ${({ theme }) => theme.colors.green400};
              }
              &:active:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.green600};
                color: ${({ theme }) => theme.colors.green600};
              }
            `
          : css`
              background-color: ${({ theme }) => theme.colors.green500};
              color: ${({ theme }) => theme.colors.white};

              :disabled {
                background-color: ${({ theme }) => theme.colors.gray325};
              }

              &:hover:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.green400};
              }
              &:active:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.green600};
              }
            `;
      case 'tertiary':
        return $variant === 'outlined'
          ? css`
              border-color: ${({ theme }) => theme.colors.purpleDark1};
              color: ${({ theme }) => theme.colors.purpleDark1};

              &:hover:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.purpleLight1};
                color: ${({ theme }) => theme.colors.purpleLight1};
              }
              &:active:not(:disabled) {
                border-color: ${({ theme }) => theme.colors.purpleDark1};
                color: ${({ theme }) => theme.colors.purpleDark1};
              }
            `
          : css`
              background-color: ${({ theme }) => theme.colors.purple};
              color: ${({ theme }) => theme.colors.white};

              &:hover:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.purpleLight1};
              }
              &:active:not(:disabled) {
                background-color: ${({ theme }) => theme.colors.purpleDark1};
              }
            `;
    }
  }}

  ${({ $variant }) => {
    return $variant === 'outlined'
      ? css`
          :disabled {
            color: ${({ theme }) => theme.colors.gray350};
            border: 0.0625rem solid ${({ theme }) => theme.colors.gray325};
          }
        `
      : css`
          :disabled {
            background-color: ${({ theme }) => theme.colors.gray325};
          }
        `;
  }}
`;

export const IconWrapper = styled.span<IconProps>`
  display: inline-flex;
  margin-right: 0.6rem;
  width: 1.25rem;

  ${({ $position }) =>
    $position === 'right' &&
    css`
      margin-left: 0.625rem;
      margin-right: 0;
    `}
`;

export const StoryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  ${Button} {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;
