import Protected from 'containers/Protected';
import Guest from 'containers/Guest';
import { useAuth } from 'data/context/AuthContext';

const AuthenticationRouter = () => {
  const { token } = useAuth();

  if (!token) {
    return <Guest />;
  }

  return <Protected />;
};

export default AuthenticationRouter;
