import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../../utils/useQueryParams';

export const useConnect = () => {
  const { push, goBack } = useHistory();
  const params = useQueryParams();
  const displayed = params.has('quota-conditions');

  const onClose = useCallback(() => {
    if (displayed) {
      goBack();
    } else {
      const currentLocation = new URL(window.location.href);
      currentLocation.searchParams.set('quota-conditions', '1');
      push(currentLocation.pathname + currentLocation.search);
    }
  }, [push, goBack, displayed]);

  return {
    isOpen: displayed,
    handle: {
      close: onClose,
    },
  };
};
