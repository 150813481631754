import { client } from 'data/client';
import { Input } from 'model/customerPortal';
import { QueryFunctionContext } from 'react-query';

type GetCustomerPortalResponse = Input;

export async function getCustomerPortal({ queryKey }: QueryFunctionContext) {
  const [, { userId, returnUrl }] = queryKey as [
    string,
    { userId: string; returnUrl: string },
  ];
  const { data } = await client.get<GetCustomerPortalResponse>(
    `/users/${userId}/stripeCustomerPortal`,
    {
      params: { returnUrl },
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}`,
    },
  );
  return data;
}
