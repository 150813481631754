import { FC, memo, useCallback } from 'react';
import { ReactComponent as SubscriptionIcon } from 'assets/images/icons/clipboard.svg';
import { ReactComponent as ShuffleIcon } from 'assets/images/icons/shuffle.svg';
import { ReactComponent as ExitIcon } from 'assets/images/icons/logout.svg';
import testingVar from 'utils/testingVar';

import { Props } from './types';
import Avatar from '../Avatar';
import {
  Container,
  Trigger,
  Dropdown,
  Item,
  ItemIcon,
  ItemText,
  Chevron,
  Account,
  Photo,
  AccountInfo,
  Name,
  Email,
  Link,
} from './styles';
import { useLogic } from './logic';

const AccountDropdown: FC<Props> = ({
  name,
  email,
  photo,
  initials,
  onAccountSwitch,
  onLogout,
  skipPlanChange,
  ...props
}) => {
  const { isOpen, anchor, handleOpen, handleClose } = useLogic();

  const onSwitch = useCallback(() => {
    if (onAccountSwitch) {
      onAccountSwitch();
      handleClose();
    }
  }, [onAccountSwitch, handleClose]);

  return (
    <Container {...props}>
      <Trigger
        aria-controls="account-menu"
        aria-haspopup="true"
        data-testid="trigger"
        data-test-id={testingVar('account-dropdown')}
        onClick={handleOpen}
      >
        <Avatar src={photo} initials={initials} />
      </Trigger>
      <Dropdown
        id="account-menu"
        anchorEl={anchor}
        keepMounted
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
      >
        <Account>
          <Photo src={photo} initials={initials} />
          <AccountInfo>
            <Name>{name}</Name>
            <Email data-test-id={testingVar('user-email')}>{email}</Email>
          </AccountInfo>
        </Account>
        {!skipPlanChange && (
          <Link
            to="/subscription"
            onClick={handleClose}
            data-testid="subscription"
          >
            <Item data-test-id={testingVar('view-subscription')}>
              <ItemIcon>
                <SubscriptionIcon />
              </ItemIcon>
              <ItemText>Subscription plan</ItemText>
              <Chevron />
            </Item>
          </Link>
        )}
        {onAccountSwitch && (
          <Item
            data-test-id={testingVar('submit-switch')}
            onClick={onSwitch}
            data-testid="switch"
          >
            <ItemIcon>
              <ShuffleIcon />
            </ItemIcon>
            <ItemText>Switch account</ItemText>
            <Chevron />
          </Item>
        )}
        <Item
          data-test-id={testingVar('submit-logout')}
          onClick={onLogout}
          data-testid="logout"
        >
          <ItemIcon>
            <ExitIcon />
          </ItemIcon>
          <ItemText>Log out</ItemText>
          <Chevron />
        </Item>
      </Dropdown>
    </Container>
  );
};

export default memo(AccountDropdown);
