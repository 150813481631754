import GwmLabel from 'assets/images/logos/gwm-label.png';
import GwmLabel2x from 'assets/images/logos/gwm-label-2x.png';
import GwmLogo from 'assets/images/logos/gwm-logo.png';
import GwmLogo2x from 'assets/images/logos/gwm-logo-2x.png';
import Container from 'components/Container';
import GoogleButton from 'components/GoogleButton';
import testingVar from 'utils/testingVar';
import {
  Aside,
  Card,
  Footer,
  Image,
  LoginContainer,
  Logo,
  Logos,
  Main,
  Nav,
  NavLink,
  Subtitle,
  Text,
  Title,
  ErrorWrapper,
  AlertIcon,
  Loader,
  ErrorContainer,
  Badges,
  Badge,
  ReviewsContainer,
  ReviewsLink,
} from './styles';
import { useConnect } from './connect';
import TosAgreementText from './TosAgreementText';
import Reviews from '../../../components/Reviews';
import marketPlaceLogos from './constants';

const Login = () => {
  const {
    clientId,
    onLogin,
    onSwitchAccount,
    onSignUp,
    isLoading,
    error,
    loginTestingVar,
  } = useConnect();

  return (
    <LoginContainer>
      <Main>
        <Container>
          <Card>
            <Image />
            {error && error.type !== 'no-user' && (
              <ErrorWrapper>
                <ErrorContainer>
                  <AlertIcon />
                  {error.type === 'session-expired' ? (
                    <span data-test-id={testingVar('session-expired')}>
                      Your session has expired, please sign in again
                    </span>
                  ) : null}
                  {error.type === 'google' ? (
                    <span>
                      There was an error using Google&apos;s sign in method
                    </span>
                  ) : null}
                  {error.type === 'can-not-create-user' ? (
                    <span>There was an error creating your account</span>
                  ) : null}
                </ErrorContainer>
              </ErrorWrapper>
            )}
            {error && error.type === 'no-user' ? (
              <>
                <ErrorWrapper>
                  <ErrorContainer>
                    <AlertIcon />
                    <span data-test-id={testingVar('no-user')}>
                      <b>{error.payload}</b> doesn’t have a Form Publisher
                      account yet.
                      <br />
                      Click below to create a new account:
                    </span>
                  </ErrorContainer>
                </ErrorWrapper>

                <GoogleButton
                  variant="SIGN_UP"
                  data-test-id={testingVar('submit-signup')}
                  onClick={() => onSignUp()}
                />
                <TosAgreementText />
              </>
            ) : (
              <>
                {clientId && !isLoading ? (
                  <>
                    <Title>Welcome to Form Publisher</Title>
                    <Subtitle>Already have a Form Publisher account?</Subtitle>
                    <GoogleButton
                      data-test-id={testingVar('submit-login')}
                      onClick={() => onLogin()}
                    />
                    {loginTestingVar ? (
                      <span data-test-id={loginTestingVar} />
                    ) : null}
                  </>
                ) : null}
                {isLoading ? <Loader size={36} /> : null}
              </>
            )}
          </Card>

          <Card>
            {clientId && !isLoading ? (
              <>
                {error && error.type === 'no-user' ? (
                  <>
                    <Subtitle>Did you want to use another account?</Subtitle>
                    <GoogleButton
                      variant="SIGN_IN_ANOTHER_ACCOUNT"
                      data-test-id={testingVar('submit-login')}
                      onClick={() => onSwitchAccount()}
                    />
                  </>
                ) : (
                  <>
                    <Subtitle>
                      Don&apos;t have a Form Publisher account yet?
                    </Subtitle>
                    <GoogleButton
                      variant="SIGN_UP"
                      data-test-id={testingVar('submit-signup')}
                      onClick={() => onSignUp()}
                    />
                    <TosAgreementText />
                  </>
                )}
              </>
            ) : null}
            {isLoading ? <Loader size={36} /> : null}
          </Card>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Nav>
            <NavLink href={process.env.REACT_APP_HELP_CENTER_URL}>
              Help center
            </NavLink>
            <NavLink href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}>
              Terms of service
            </NavLink>
            <NavLink href={process.env.REACT_APP_PRIVACY_POLICY_URL}>
              Privacy policy
            </NavLink>
            <NavLink href={process.env.REACT_APP_COOKIE_POLICY_URL}>
              Cookie policy
            </NavLink>
            <NavLink href={process.env.REACT_APP_DATA_PROCESSING_AGREEMENT_URL}>
              Data processing agreement
            </NavLink>
          </Nav>
        </Container>
      </Footer>
      <Aside>
        <Text>Trusted by over 100,000 organizations and 10+ million users</Text>
        <Logos>
          {Object.values(marketPlaceLogos).map((companyLogos) => (
            <Logo key={`${companyLogos.id}`}>
              <source src={companyLogos.webp} type="image/webp" />
              <source src={companyLogos.png} type="image/png" />
              <img src={companyLogos.png} alt={companyLogos.alt} />
            </Logo>
          ))}
        </Logos>

        <ReviewsLink href="https://gsuite.google.com/marketplace/app/form_publisher_form_to_pdf_google_docs_t/827172627657">
          <ReviewsContainer>
            <Reviews rating={4.6} reviews="3.7K" />
          </ReviewsContainer>
        </ReviewsLink>

        <Badges>
          <Badge>
            <source srcSet={`${GwmLogo}, ${GwmLogo2x} 2x`} type="image/png" />
            <img
              src={GwmLogo}
              alt="Most popular G Suite Marketplace app with more than 10 million users"
            />
          </Badge>
          <Badge>
            <source srcSet={`${GwmLabel}, ${GwmLabel2x} 2x`} type="image/png" />
            <img
              src={GwmLabel}
              alt="Most popular G Suite Marketplace app with more than 10 million users"
            />
          </Badge>
        </Badges>
      </Aside>
    </LoginContainer>
  );
};

export default Login;
