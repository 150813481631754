import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';

import styled from 'styled-components';

export const AccountHeader = styled.div`
  height: 9rem;
  background-color: ${({ theme }) => theme.colors.purpleDark1};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0.04;
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 2.875rem;
    transform: rotate(30deg);
  }

  &::before {
    top: -3rem;
    left: -6.5rem;
  }
  &::after {
    top: -3rem;
    right: -4rem;
  }
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0.5rem;
`;

export const Domain = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 160%;
  letter-spacing: -0.0066em;
`;

export const EmailsLimit = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme }) => theme.colors.gray2};
  font-size: 0.875rem;
  line-height: 160%;
  margin-top: 0.5rem;
  letter-spacing: -0.0066em;
`;

export const IconWrapper = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 1.375rem;
  svg {
    height: 100%;
    width: 100%;
  }
`;
export const LinkIcon = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.gray2};
  margin-left: auto;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  &:focus-visible {
    outline-offset: -0.5rem;
  }
`;

export const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
`;

export const IconPlaceholder = styled.div`
  background-color: ${({ theme }) => theme.colors.gray2};
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
`;

export const LinkIconPlaceholder = styled.div`
  background-color: ${({ theme }) => theme.colors.gray2};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-left: auto;
`;

export const TextPlaceholder = styled.div`
  height: 1.25rem;
  width: 7.5rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;
`;
