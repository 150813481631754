import { FC } from 'react';
import DataView from 'components/DataView';
import DesktopTable from 'components/DesktopTable';
import Row from 'components/MobileTable/Row';
import MobileTable from 'components/MobileTable';
import testingVar from 'utils/testingVar';
import { Props } from './types';
import Search from './table/filter';
import Exporter from './table/exporter';
import NotFound from './table/notFound';
import { useConnect } from './connect';
import Empty from './table/empty';

const ListOfForms: FC<Props> = ({ forms, isMobile, isLoading }) => {
  const { columns, formsTestingVar } = useConnect();

  return (
    <>
      <DataView>
        {isMobile ? (
          <MobileTable
            data={forms}
            columns={columns}
            loading={isLoading}
            row={(item) => (
              <Row
                data-test-id={testingVar(`table-row`)}
                key={item.id}
                id={item.id}
                name={item.formTitle}
                generated={item.totalGeneratedFilesCount}
                date={item.lastGeneratedFileDate}
              />
            )}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        ) : (
          <DesktopTable
            data={forms}
            loading={isLoading}
            columns={columns}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        )}
        {formsTestingVar ? <span data-test-id={formsTestingVar} /> : null}
      </DataView>
    </>
  );
};

export default ListOfForms;
