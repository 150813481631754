import { Title, Subtitle } from 'components/DesktopTable/Empty/styles';

const FormsNotFound = () => {
  return (
    <>
      <Title>No results found</Title>
      <Subtitle>Sorry, there is no form that fits with your search.</Subtitle>
    </>
  );
};

export default FormsNotFound;
