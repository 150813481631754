// import { useState, useEffect } from 'react';
import SocialLogInButton from 'components/SocialLogInButton';
import { ReactComponent as GoogleLogoIcon } from 'assets/images/icons/google.svg';
import { ReactComponent as FacebookCircleIcon } from 'assets/images/icons/facebook-circle.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/images/icons/microsoft.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/icons/twitter.svg';
import testingVar from 'utils/testingVar';
import { useAuth } from 'data/context/AuthContext';
import { Card, Title, ButtonsColumn } from './styles';
import { Props } from './types';

const ValidationLogIn = ({ onLoginWith, loaders }: Props) => {
  const { onWorkflowLogin } = useAuth();

  return (
    <Card>
      <Title data-test-id={testingVar('validation-workflow-login')}>
        Log in to sign the document
      </Title>
      <ButtonsColumn>
        <SocialLogInButton
          fullWidth
          socialPlatform="google"
          icon={<GoogleLogoIcon />}
          onClick={() => onWorkflowLogin()}
          loading={loaders.google}
          data-test-id="validation-login-google"
        />
        <SocialLogInButton
          fullWidth
          socialPlatform="facebook"
          icon={<FacebookCircleIcon />}
          onClick={() => onLoginWith('facebook')}
          loading={loaders.facebook}
          data-test-id="validation-login-facebook"
        />
        <SocialLogInButton
          fullWidth
          socialPlatform="twitter"
          icon={<TwitterIcon />}
          onClick={() => onLoginWith('twitter')}
          loading={loaders.twitter}
          data-test-id="validation-login-twitter"
        />
        <SocialLogInButton
          fullWidth
          socialPlatform="microsoft"
          icon={<MicrosoftIcon />}
          onClick={() => onLoginWith('microsoft')}
          loading={loaders.microsoft}
          data-test-id="validation-login-microsoft"
        />
      </ButtonsColumn>
    </Card>
  );
};
export default ValidationLogIn;
