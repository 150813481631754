import { Container as Placeholder } from 'components/DesktopTable/Placeholder/styles';
import { Container as Empty } from 'components/DesktopTable/Empty/styles';
import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 1 / -1;
  ${Placeholder}, ${Empty} {
    max-width: var(--maxWidth);
    margin-left: auto;
    margin-right: auto;
  }
  > table {
    thead tr,
    tbody {
      max-width: var(--maxWidth);
      margin: 0 auto;
    }
  }
`;
