import { createClient } from 'data/client';
import { Input as Approval } from 'model/approval';
import { Input as ApprovalStatus } from 'model/approvalStatus';
import { QueryFunctionContext } from 'react-query';

export type GetApprovalDto = {
  encodedCreatorKey: string;
  formId: string;
  responseId: string;
  encodedValidatorEmail: string;
  accessToken?: string;
};

export type GetApprovalRequest = QueryFunctionContext & {
  accessToken?: string;
};

export async function getApproval({ queryKey }: GetApprovalRequest) {
  const [
    ,
    {
      encodedCreatorKey,
      formId,
      responseId,
      encodedValidatorEmail,
      accessToken,
    },
  ] = queryKey as [string, GetApprovalDto];
  const client = createClient();
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

  const { data } = await client.get<Approval>(
    `/users/${encodedCreatorKey}/forms/${formId}/approvals/${responseId}/${encodedValidatorEmail}`,
    { headers },
  );

  return data;
}

export type UpdateApprovalRequest = GetApprovalDto &
  ApprovalStatus & { accessToken: string };

export function postApproval({
  encodedCreatorKey,
  formId,
  responseId,
  encodedValidatorEmail,
  comment,
  status,
  hasSigned,
  accessToken,
}: UpdateApprovalRequest) {
  const client = createClient();
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  return client.post<ApprovalStatus>(
    `/users/${encodedCreatorKey}/forms/${formId}/approvals/${responseId}/${encodedValidatorEmail}`,
    { status, comment, hasSigned },
    { headers },
  );
}
