import Bold from 'components/Bold';
import { Form } from 'model/form';
import { formatDayMonthYear } from 'utils/dates';
import { Column } from 'components/DesktopTable/types';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import TextWrap from 'components/TextWrap';
import { Tooltip, TooltipStyles } from 'components/OrderSummary/styles';
import { Container } from 'components/TextWrap/styles';
import { SheetLink, SheetIcon } from '../../styles';

const MAX_CHAR_LENGTH = 28;

export const getColumns = () =>
  [
    {
      Header: 'Name',
      accessor: 'formTitle',
      width: 228,
      Cell: ({ value, row }) => {
        const { id } = row.original as never as Form;
        const displayTooltip = value.length >= MAX_CHAR_LENGTH;
        return (
          <>
            <TooltipStyles />
            <Tooltip
              placement="top"
              title={value}
              disableHoverListener={!displayTooltip}
            >
              <Container>
                <TextWrap>
                  <Bold style={{ fontVariant: 'tabular-nums' }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://docs.google.com/forms/d/${id}/edit`}
                    >
                      {value}
                    </a>
                  </Bold>
                </TextWrap>
              </Container>
            </Tooltip>
          </>
        );
      },
    },
    {
      Header: 'Last File Generated On',
      accessor: 'lastGeneratedFileDate',
      width: 134,
      filter: 'unix',
      sortType: 'datetime',
      defaultSorted: 'desc',
      alignment: 'right',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatDayMonthYear} />
      ),
    },
    {
      Header: 'Number of files',
      accessor: 'totalGeneratedFilesCount',
      width: 130,
      alignment: 'right',
    },
    {
      Header: 'File link',
      accessor: 'outputFolderUrl',
      alignment: 'right',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value }) => {
        if (!value) return null;

        return (
          <SheetLink href={value} target="_blank" rel="noopener noreferrer">
            <SheetIcon />
          </SheetLink>
        );
      },
    },
  ] as Column[];
