import { captureException, setUser as setSentryUser } from '@sentry/minimal';
import { useApproval } from 'data/hooks/useApproval';
import { useCreateOrUpdateSignature } from 'data/hooks/useCreateOrUpdateSignature';
import { useDeleteSignature } from 'data/hooks/useDeleteSignature';
import { useSignature } from 'data/hooks/useSignature';
import { useUpdateApproval } from 'data/hooks/useUpdateApproval';
import { useSocialLogin } from 'factories/SocialLoginFactory';
import { SocialLoginPlatform } from 'factories/SocialLoginFactory/types';
import { ErrorCodes } from 'factories/SocialLoginFactory/useFirebase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import type { Params } from './types';
import { logSolvayMigrationUser } from '../../../data/context/AuthContext/logger';

export const useConnect = () => {
  const {
    userKeyEncoded,
    formId,
    responseId,
    emailValidatorEncoded,
    displayAwaiting,
    collectEmailAddress,
    hasMarkerSignature,
  } = useParams<Params>();

  const {
    credentials,
    errors,
    loaders,
    login,
    isUserLoggedIn,
    areCookiesDisabled,
    logout,
  } = useSocialLogin();

  const isUserLoggedInRes = isUserLoggedIn();

  const [authorizationError, setAuthorizationError] = useState(false);

  const hasMandatoryParams = useMemo(
    () =>
      [userKeyEncoded, formId, responseId, emailValidatorEncoded].every(
        Boolean,
      ),
    [userKeyEncoded, formId, responseId, emailValidatorEncoded],
  );

  const { approval, status: approvalQueryStatus } = useApproval({
    accessToken: credentials?.accessToken,
    encodedCreatorKey: userKeyEncoded,
    formId,
    responseId,
    encodedValidatorEmail: emailValidatorEncoded,
  });

  const { updateApproval, isError: updateApprovalError } = useUpdateApproval();

  const { signature, status: signatureQueryStatus } = useSignature({
    userId: credentials?.email,
    accessToken: credentials?.accessToken,
    onError: (e) => {
      const status = e.response?.status;
      const expectedErrorCodes = [401];
      if (e != null && status && expectedErrorCodes.includes(status)) {
        setSentryUser({
          email: credentials?.email,
        });
        captureException(e);
      }
    },
  });

  const { createOrUpdateSignature, isError: createOrUpdateSignatureError } =
    useCreateOrUpdateSignature();

  const deleteSignature = useDeleteSignature();

  const emailValidator = useMemo(() => {
    try {
      return atob(emailValidatorEncoded);
    } catch {
      return '';
    }
  }, [emailValidatorEncoded]);

  /*
  START SOLVAY MIGRATION
  */

  /* Specific handling for migrated Solvay users to ensure validator email
  is correctly authorized even if the migrated user email has changed */
  const allowSolvayUser = useMemo(() => {
    if (emailValidator.includes('solvay')) {
      const validatorName = emailValidator.substring(
        0,
        emailValidator.lastIndexOf('@'),
      );
      const userName = credentials?.email.substring(
        0,
        credentials?.email.lastIndexOf('@'),
      );
      if (validatorName === userName) {
        logSolvayMigrationUser(validatorName, userName);
        return true;
      }
    }
    return false;
  }, [credentials, emailValidator]);

  const isAuthorized = useMemo(() => {
    return approval?.signatureRequired
      ? credentials?.email === emailValidator || allowSolvayUser
      : true;
  }, [credentials, emailValidator, approval, allowSolvayUser]);

  const areTherePopupErrors = useMemo(() => {
    return Object.values(errors).find(
      (el) => el === ErrorCodes.POPUPS_BLOCKED_BY_BROWSER,
    );
  }, [errors]);

  const areThereAnyErrors = useMemo(() => {
    const socialLoginErrors = Object.values(errors).map(Boolean);
    return [
      !approval && approvalQueryStatus !== 'loading',
      updateApprovalError,
      createOrUpdateSignatureError,
      ...socialLoginErrors,
      areCookiesDisabled,
    ].some(Boolean);
  }, [
    approval,
    approvalQueryStatus,
    errors,
    updateApprovalError,
    createOrUpdateSignatureError,
    areCookiesDisabled,
  ]);

  const statusesForAuthentication = ['FEEDBACK', 'PENDING'];
  const shouldUserAuthenticate = useMemo(
    () =>
      approval &&
      statusesForAuthentication.includes(approval?.status) &&
      approval.signatureRequired &&
      !isUserLoggedInRes &&
      !isAuthorized,
    [approval, isAuthorized, isUserLoggedInRes],
  );

  const shouldFileBeValidated = useMemo(() => {
    return (
      approval &&
      approval.status === 'PENDING' &&
      signatureQueryStatus !== 'loading'
    );
  }, [approval, signatureQueryStatus]);

  const fileWasGivenFeedback = useMemo(
    () => approval && approval.status === 'FEEDBACK',
    [approval],
  );

  const isFileRejected = useMemo(
    () => approval && approval.status === 'REJECTED',
    [approval],
  );

  const isFileApproved = useMemo(
    () => approval && approval.status === 'APPROVED',
    [approval],
  );

  const status = useMemo(() => {
    if (areThereAnyErrors) return 'error' as const;
    if (authorizationError) return 'unauthorized' as const;
    if (shouldUserAuthenticate) {
      if (isUserLoggedInRes === undefined) return 'loading' as const;
      return 'authenticate' as const;
    }
    if (shouldFileBeValidated) return 'pending' as const;
    if (fileWasGivenFeedback) return 'feedback' as const;
    if (isFileRejected) return 'rejected' as const;
    if (isFileApproved) return 'approved' as const;
    return 'loading' as const;
  }, [
    areThereAnyErrors,
    authorizationError,
    shouldUserAuthenticate,
    shouldFileBeValidated,
    fileWasGivenFeedback,
    isFileRejected,
    isFileApproved,
    isUserLoggedInRes,
  ]);

  useEffect(() => {
    if (
      !!credentials?.email.length &&
      !isAuthorized &&
      !shouldUserAuthenticate &&
      shouldUserAuthenticate !== undefined
    ) {
      setAuthorizationError(true);
    } else if (!isAuthorized && shouldUserAuthenticate) {
      setAuthorizationError(false);
    }
  }, [isAuthorized, shouldUserAuthenticate]);

  const onLoginWith = useCallback(
    async (platform: SocialLoginPlatform) => {
      const { credentials: localCredentials } = await login(platform);
      if (
        localCredentials !== undefined &&
        localCredentials.email !== emailValidator
      ) {
        setAuthorizationError(true);
      }
    },
    [login, emailValidator],
  );

  const resetAuthorizationError = useCallback(
    () =>
      isAuthorized ? setAuthorizationError(false) : window.location.reload(),
    [isAuthorized],
  );
  return {
    approval,
    approvalQueryStatus,
    updateApproval,
    signature,
    signatureQueryStatus,
    userEmail: credentials?.email ?? '',
    accessToken: credentials?.accessToken ?? '',
    createOrUpdateSignature,
    deleteSignature,
    collectEmailAddress,
    displayAwaiting: displayAwaiting === 'displayWaiting',
    formId,
    responseId,
    userKeyEncoded,
    emailValidatorEncoded,
    hasMandatoryParams,
    hasMarkerSignature,
    isAuthorized,
    loaders,
    status,
    onLoginWith,
    resetAuthorizationError,
    areTherePopupErrors,
    areCookiesDisabled,
    logout,
  };
};
