import { FC, useMemo } from 'react';
import Star from 'components/Star';
import { nanoid } from 'nanoid';
import {
  DotStyle,
  ReviewsContainer,
  ReviewText,
  StarsContainer,
} from './styles';

const starsRating: (rating: number) => { percentage: number; id: string }[] = (
  rating,
) =>
  Array(5)
    .fill(null)
    .map((_, index) => ({
      percentage: rating - index > 1 ? 100 : Math.floor((rating - index) * 100),
      id: nanoid(),
    }));

const Reviews: FC<{ rating: number; reviews: string }> = ({
  rating,
  reviews,
}) => {
  const starsRatingArr = useMemo(() => starsRating(rating), [rating]);
  return (
    <ReviewsContainer>
      <StarsContainer>
        {starsRatingArr.map(({ percentage, id }) => {
          return <Star percentage={percentage} id={id} key={id} />;
        })}
      </StarsContainer>
      <ReviewText>
        <b>{rating}</b>&nbsp;/ 5&nbsp;<DotStyle>•</DotStyle>&nbsp;
        <b>{reviews}</b>&nbsp;reviews
      </ReviewText>
    </ReviewsContainer>
  );
};

export default Reviews;
