import { FC } from 'react';
import { ReactComponent as SettingsIcon } from 'assets/images/icons/settings.svg';
import { Link, IconWrapper, LinkIcon } from '../styles';
import type { Props } from './types';

/**
 * We only allow subscription to be "bought again" in case the payment method is PayPal
 * and the current subscription is CANCELED
 * If the user stripe customer - we allow them to renew their subscription
 * through their customer portal link - "managePlanLink"
 */

const RENEW_SUBSCRIPTION_TEXT = 'You can renew your subscription.';
const BUY_AGAIN_SUBSCRIPTION_TEXT = 'You can subscribe again to restart it.';

const RenewSubscriptionLink: FC<Props> = ({
  isStripeCustomer,
  managePlanLink,
  hasActiveLicense,
}) => {
  const linkHref = isStripeCustomer
    ? managePlanLink
    : `${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`;

  const linkText =
    isStripeCustomer && hasActiveLicense
      ? RENEW_SUBSCRIPTION_TEXT
      : BUY_AGAIN_SUBSCRIPTION_TEXT;
  return (
    <Link href={linkHref} target="_blank" rel="noopener noreferrer">
      <IconWrapper>
        <SettingsIcon />
      </IconWrapper>
      Auto Renewal has been cancelled.
      <br />
      {linkText}
      <LinkIcon />
    </Link>
  );
};

export default RenewSubscriptionLink;
