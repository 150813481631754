/* eslint-disable no-debugger */
import { getForms } from 'data/requests/forms';
import { normalizeForm } from 'model/form';
import { useQuery } from 'react-query';

export const useForms = (userId?: string) => {
  const { data, status } = useQuery(['forms', { userId }], getForms, {
    enabled: !!userId,
    refetchOnWindowFocus: true,
  });

  const forms = (data || []).map(normalizeForm);

  return { forms, status };
};
