import ValidationResponse from 'components/ValidationResponse';
import { useCreateOrUpdateSignature } from 'data/hooks/useCreateOrUpdateSignature';
import { useUpdateApproval } from 'data/hooks/useUpdateApproval';
import { createContext, ReactNode, useContext, useState } from 'react';

type Transition = 'APPROVED' | 'REJECTED' | 'FEEDBACK';

type ValidationContextProps = {
  userId: string;
  accessToken: string;
  userKeyEncoded: string;
  formId: string;
  responseId: string;
  emailValidatorEncoded: string;
  transitionTo: (status: Transition) => void;
  updateApproval: ReturnType<typeof useUpdateApproval>['updateApproval'];
  createOrUpdateSignature: ReturnType<
    typeof useCreateOrUpdateSignature
  >['createOrUpdateSignature'];
};

type ValidationProviderProps = Omit<ValidationContextProps, 'transitionTo'> & {
  children: ReactNode;
};

const ValidationContext = createContext<ValidationContextProps>({
  userId: '',
  accessToken: '',
  userKeyEncoded: '',
  formId: '',
  responseId: '',
  emailValidatorEncoded: '',
  transitionTo: () => {},
  updateApproval: () => Promise.resolve(),
  createOrUpdateSignature: () => Promise.resolve(),
});

export const useValidationContext = () => useContext(ValidationContext);

export function ValidationProvider({
  children,
  ...rest
}: ValidationProviderProps) {
  const [transition, transitionTo] = useState<Transition | undefined>(
    undefined,
  );

  const content = (() => {
    switch (transition) {
      case 'APPROVED':
        return <ValidationResponse status="approved" />;
      case 'REJECTED':
        return <ValidationResponse status="rejected" />;
      case 'FEEDBACK':
        return <ValidationResponse status="information-requested" />;
      default:
        return children;
    }
  })();

  return (
    <ValidationContext.Provider value={{ ...rest, transitionTo }}>
      {content}
    </ValidationContext.Provider>
  );
}
