import styled from 'styled-components';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ReactComponent as ChevronIcon } from 'assets/images/icons/chevron-right.svg';
import { from } from 'styles/media';
import ModalLink from 'components/ModalLink';
import Avatar from '../Avatar';

const TRIGGER_SIZE = 32;

export const Container = styled.div``;

export const Trigger = styled.button`
  all: unset;
  cursor: pointer;

  :focus {
    outline-offset: 0.125rem;
    outline: 0.125rem auto;
    border-radius: 50%;
  }
`;

export const Dropdown = styled(Menu).attrs(() => ({
  anchorOrigin: {
    vertical: TRIGGER_SIZE + 14,
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}))`
  font-size: 0.875rem;
  line-height: 1.375rem;

  .MuiTypography-body1 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-family: inherit;
  }

  .MuiPaper-root {
    width: 20.875rem;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0rem 0.25rem 0.75rem rgba(107, 124, 147, 0.2);
    border-radius: 0.625rem;
    font-family: ${({ theme }) => theme.fonts.inter};
  }

  .MuiList-root {
    padding: 0;
    > * + * {
      border-top: 0.0625rem solid ${({ theme }) => theme.colors.purple250};
    }
  }

  ${from.mobile} {
    font-size: inherit;
    line-height: inherit;

    .MuiTypography-body1 {
      font-size: inherit;
      line-height: inherit;
    }
  }
`;

export const Account = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  outline: none;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.purple250};
`;

export const Photo = styled(Avatar)`
  margin-right: 1rem;
`;

export const AccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Name = styled.span`
  margin-bottom: 0.25rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  line-height: 160%;
  color: ${({ theme }) => theme.colors.purple4};
`;

export const Email = styled.span`
  color: ${({ theme }) => theme.colors.purple3};
  line-height: 160%;
  letter-spacing: -0.0066em;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const Link = styled(ModalLink)`
  text-decoration: none;
`;

export const Item = styled(MenuItem)`
  &.MuiMenuItem-root {
    color: ${({ theme }) => theme.colors.purple4};
    padding: 1.25rem 1.5625rem;
    font-family: inherit;
  }
`;

export const ItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    margin-right: 1.5rem;
    min-width: unset;
    color: ${({ theme }) => theme.colors.purple375};
  }
`;

export const ItemText = styled(ListItemText)`
  flex: 1;
`;

export const Chevron = styled(ChevronIcon)`
  color: ${({ theme }) => theme.colors.purple2};
`;
