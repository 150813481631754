import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import DefaultCanvasDraw from 'react-canvas-draw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.purple4};
`;

export const LabelRow = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.75rem;
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
`;

export const ComponentContainer = styled.div`
  position: relative;
  width: 100%;
  &:focus,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Component = styled.div.attrs(() => ({
  tabIndex: 0,
}))<{ $hasError: boolean }>`
  background-color: ${({ theme }) => addAlpha(theme.colors.purple2, 0.25)};
  border: 0.0625rem solid
    ${({ $hasError, theme }) =>
      $hasError ? theme.colors.red600 : theme.colors.purple2};
  border-radius: 0.3125rem;
  color: inherit;
  cursor: none;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  transition: background-color ease-in-out 500ms;

  &:disabled {
    cursor: not-allowed;
  }
  &:focus,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => addAlpha(theme.colors.purple3, 0.5)};
  }

  ${({ $hasError, theme }) =>
    $hasError
      ? null
      : css`
          &:focus:not(:disabled),
          &:hover:not(:disabled) {
            border: 0.0625rem solid ${addAlpha(theme.colors.purple3, 0.5)};
          }
        `}
`;

export const CanvasDraw = styled(DefaultCanvasDraw)`
  width: 100%;

  & canvas {
    height: calc(100% - 2px) !important;
    width: calc(100% - 2px) !important;
  }
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.red600};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  min-height: 1.25rem;
  display: flex;
  align-items: center;
`;
