import { FC, memo } from 'react';
import {
  IconWrapper,
  TextWrapper,
  Button as StyledButton,
  Loader,
} from './styles';
import { Props } from './types';

const ValidationButton: FC<Props> = ({
  children,
  color = 'primary',
  variant = 'outlined',
  icon,
  iconPosition = 'left',
  fullWidth,
  loading = false,
  ...props
}) => {
  return (
    <StyledButton
      $color={color}
      $variant={variant}
      $iconPosition={iconPosition}
      $fullWidth={fullWidth}
      {...props}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {!!icon && <IconWrapper $position={iconPosition}>{icon}</IconWrapper>}
          <TextWrapper>{children}</TextWrapper>
        </>
      )}
    </StyledButton>
  );
};

export default memo(ValidationButton);
