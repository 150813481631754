import testingVar from 'utils/testingVar';
import { Title, Text, WarningIcon, Button } from '../styles';

const reloadPage = () => window.location.reload();
const UnauthorizedCard = ({
  currentEmail,
  expectedEmail,
  onReset = reloadPage,
}: {
  // eslint-disable-next-line react/require-default-props
  currentEmail?: string;
  // eslint-disable-next-line react/require-default-props
  expectedEmail?: string;
  // eslint-disable-next-line react/require-default-props
  onReset?: () => void;
}) => {
  return (
    <>
      <WarningIcon />
      <Title
        data-test-id={testingVar('validation-workflow-error-unauthorized')}
      >
        You are not allowed to validate this file
      </Title>
      <Text>
        This is most likely because you are logged in the wrong account. <br />
        In order to review this request, please log out of {`"${currentEmail}"`}{' '}
        and log in with {`"${expectedEmail}"`}
        <br />
        If the problem persists, please{' '}
        <a href="https://support.form-publisher.com/hc/en-us/requests/new">
          contact support
        </a>
      </Text>
      <Button
        onClick={onReset}
        data-test-id={testingVar(
          'validation-workflow-error-unauthorized-retry',
        )}
      >
        Try again
      </Button>
    </>
  );
};

export default UnauthorizedCard;
