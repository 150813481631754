import { client } from 'data/client';

export async function postCancelAutoRenewal({
  subscriptionId,
}: {
  subscriptionId: string;
}) {
  const response = await client.post(
    `/_ah/api/subscriptions/v1/cancelAutoRenewal`,
    { subscriptionId },
    { baseURL: `${process.env.REACT_APP_BILLING_API_URL}` },
  );
  return response;
}
