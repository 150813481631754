import styled from 'styled-components';
import DefaultBaseModal from 'components/BaseModal';
import { ReactComponent as DefaultCloseIcon } from 'assets/images/icons/close.svg';
import addAlpha from 'utils/addAlpha';
import { from } from 'styles/media';

export const BaseModal = styled(DefaultBaseModal)`
  ${from.mobile} {
    & .MuiPaper-root {
      min-width: 28rem;
    }
  }
`;

export const ModalHeader = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1.25rem;
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.purple4};
  flex: 1;
`;

export const CloseButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  width: 100%;
  height: 100%;
`;

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 1rem;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.purple3};
`;

export const Content = styled.div`
  padding: 2.5rem;
`;
