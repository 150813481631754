import { FC, memo } from 'react';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { formatMonthDay } from 'utils/dates';
import { Props } from './types';
import {
  Datetime,
  RowContainer,
  Column,
  InfoItem,
  Info,
  Name,
} from '../styles';
import { Avatar } from './styles';

const UserRow: FC<Props> = ({
  name,
  initials,
  photo,
  sentMonthly,
  sentAll,
  date,
  ...props
}) => {
  return (
    <RowContainer {...props}>
      <Column>
        <Avatar src={photo} initials={initials} />
      </Column>
      <Column>
        <Name>{name}</Name>
        <Info>
          <InfoItem>{sentMonthly} this month</InfoItem>
          <InfoItem>{sentAll} all time</InfoItem>
        </Info>
      </Column>
      <Column>
        <Datetime>
          {date ? (
            <DateTimeCell value={date} formatFunction={formatMonthDay} />
          ) : null}
        </Datetime>
      </Column>
    </RowContainer>
  );
};

export default memo(UserRow);
