import firebase, { signInWithPopup } from '../useFirebase';

async function loginWithFacebook() {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  return signInWithPopup(facebookProvider);
}

export default {
  loader: async () => {},
  initializer: async () => {},
  login: loginWithFacebook,
};
