import styled, { css, keyframes } from 'styled-components';
import addAlpha from 'utils/addAlpha';

export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => addAlpha(theme.colors.purple1, 0.7)},
      ${({ theme }) => theme.colors.purple1}
    );
  }
`;

export const Row = styled.div`
  display: flex;
  height: 1rem;
  & + & {
    margin-top: 2rem;
  }
`;

export const CellContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const loadAnimation = keyframes`
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(700%);
    }
`;

export const Cell = styled.div.attrs(
  ({ $width }: { $width: string | number | undefined }) => ({
    style: { width: `${$width}px` },
  }),
)<{ $width: string | number | undefined; $animated: boolean }>`
  background-color: ${({ theme }) => theme.colors.purple2};
  border-radius: 2.125rem;
  position: relative;
  overflow: hidden;

  ${({ theme, $animated }) =>
    $animated &&
    css`
      &::after {
        content: '';
        background-image: linear-gradient(
          to right,
          transparent,
          ${addAlpha(theme.colors.purple3, 0.2)},
          transparent
        );
        width: 25%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        animation: ${loadAnimation} 800ms ease-in-out infinite;
      }
    `};
`;
