import { FC, memo, useRef } from 'react';
import Button from 'components/Button';
import useLogic from './logic';
import { CanvasDrawExtendedProps, Props } from './types';
import {
  Component,
  Container,
  ComponentContainer,
  Label,
  LabelRow,
  CanvasDraw,
  Error,
} from './styles';

const SignatureInput: FC<Props> = ({
  className,
  label,
  name = 'signature',
  imgSrc,
  ...props
}) => {
  const signatureRef = useRef<CanvasDrawExtendedProps | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    clearSignature,
    setHasDrawn,
    saveSignature,
    hasDrawn,
    canvasWidth,
    hasError,
    error,
  } = useLogic({
    signatureRef,
    name,
    imgSrc,
    containerRef,
  });

  return (
    <Container className={className} ref={containerRef} {...props}>
      <LabelRow>
        <Label>{label ?? 'Add your signature'}</Label>
        <Button
          type="button"
          onClick={clearSignature}
          variant="outline"
          size="small"
          disabled={!hasDrawn && !imgSrc}
        >
          Reset
        </Button>
      </LabelRow>
      <ComponentContainer>
        <Component $hasError={hasError}>
          <CanvasDraw
            ref={signatureRef}
            brushRadius={1}
            lazyRadius={0}
            brushColor="#242221"
            hideGrid
            backgroundColor="transparent"
            canvasHeight={canvasWidth / 2}
            canvasWidth={canvasWidth}
            onChange={() => {
              setHasDrawn();
              saveSignature();
            }}
          />
        </Component>
      </ComponentContainer>
      <Error>{hasError ? error : null}</Error>
    </Container>
  );
};

export default memo(SignatureInput);
