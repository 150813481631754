import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { Input as User, normalizeUser } from 'model/user';
import { getUser } from 'data/requests/users';

export function useUser(userId: string, options?: UseQueryOptions<User>) {
  const client = useQueryClient();
  const initialData =
    userId === 'me' ? (client.getQueryData('me') as User) : undefined;
  const { data, ...rest } = useQuery(['user', userId], getUser, {
    refetchOnMount: false,
    enabled: !!userId,
    initialData,
    ...options,
  });
  const user = data && normalizeUser(data);
  return { user, ...rest };
}
