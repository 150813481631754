import { useEffect, useState } from 'react';
import useElementDimensions from 'utils/useElementDimensions';
import { useField } from 'formik';
import { drawImageCanvas } from 'utils/drawImageCanvas';
import { LogicProps } from './types';

const useSignatureImage = ({ signatureUrl }: { signatureUrl: string }) => {
  const [signatureImage, setSignatureImage] = useState<HTMLImageElement>();

  useEffect(() => {
    if (!signatureUrl) return;
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      setSignatureImage((previousImage) => {
        return previousImage !== undefined ? previousImage : image;
      });
    };
    image.src = signatureUrl;
  }, [signatureUrl]);

  return { signatureImage };
};

const useLogic = ({ signatureRef, name, imgSrc, containerRef }: LogicProps) => {
  const [hasDrawn, setHasDrawn] = useState(false);
  const [, { error, touched }, { setValue }] = useField<string>(name);
  const { width } = useElementDimensions(containerRef);
  const [canvasWidth, setCanvasWidth] = useState(width);
  const hasError = !!error && touched;
  const { signatureImage } = useSignatureImage({ signatureUrl: imgSrc ?? '' });

  useEffect(() => {
    if (!imgSrc) return;
    setValue(imgSrc ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc]);

  useEffect(() => {
    if (!signatureImage) return;
    const drawingContext = signatureRef.current?.ctx.drawing;
    if (!drawingContext) return;
    setTimeout(
      () =>
        drawImageCanvas({
          ctx: drawingContext,
          img: signatureImage,
          x: 0,
          y: 0,
          w: width,
          h: width * 0.5,
        }),
      150,
    );
  }, [signatureImage, signatureRef, width]);

  useEffect(() => {
    setCanvasWidth(width);
  }, [width]);

  const handleHasDrawn = () => {
    setHasDrawn(!!signatureRef?.current?.lines);
  };

  const saveSignature = () => {
    const image = signatureRef?.current?.canvas?.drawing.toDataURL();
    setValue(image ?? '');
  };

  const clearSignature = () => {
    setValue('');
    signatureRef?.current?.clear();
  };

  return {
    saveSignature,
    clearSignature,
    hasDrawn,
    setHasDrawn: handleHasDrawn,
    containerRef,
    canvasWidth,
    hasError,
    error,
  };
};

export default useLogic;
