import styled from 'styled-components';
import { ReactComponent as Hamburger } from 'assets/images/icons/hamburger.svg';
import { ReactComponent as HelpCircle } from 'assets/images/icons/help-circle.svg';
import addAlpha from 'utils/addAlpha';
import { from } from 'styles/media';
import { Link as DefaultLink } from 'react-router-dom';
import Avatar from '../Avatar';
import DefaultAccountDropdown from '../AccountDropdown';

export const Container = styled.div`
  display: flex;
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
  padding: 0.75rem 1rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.purple1};

  ${from.tablet} {
    padding: 1.25rem 2rem;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  padding: 0;
`;

export const MenuIcon = styled(Hamburger)``;

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const AccountPlaceholder = styled(Avatar)`
  height: 2.125rem;
  width: 2.125rem;
  margin-left: 1.25rem;
`;

export const AccountDropdown = styled(DefaultAccountDropdown)`
  margin-left: 1.25rem;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  border-radius: 0.25rem;
  border: 0.0625rem solid transparent;
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  justify-content: center;
  line-height: 1.375rem;
  padding: 0.25rem 1rem;
  transition: all 0.2s ease;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.purple4};

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.orangeLight1};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.orangeDark2};
    outline: none;
  }
`;

export const Help = styled.a`
  margin-left: 1.125rem;
  display: flex;
  border-radius: 50%;

  :focus {
    outline-offset: 0.125rem;
    outline-color: black;
  }
`;

export const HelpIcon = styled(HelpCircle)``;
