import styled from 'styled-components';

import { from } from 'styles/media';

export const ContainerDiv = styled.div`
  margin: 0 auto;
  max-width: 58.5rem;
  padding: 0 0.625rem;

  ${from.smallMobile} {
    padding: 0 1rem;
  }

  ${from.laptop} {
    padding: 0 2rem;
  }
`;
