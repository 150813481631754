import { FC, memo } from 'react';
import { IconWrapper, TextWrapper, Button as StyledButton } from './styles';
import { Props } from './types';

const Button: FC<Props> = ({
  children,
  variant,
  size = 'small',
  icon,
  iconPosition = 'left',
  ...props
}) => {
  return (
    <StyledButton
      $variant={variant}
      $size={size}
      $iconPosition={iconPosition}
      {...props}
    >
      {!!icon && <IconWrapper $position={iconPosition}>{icon}</IconWrapper>}
      <TextWrapper>{children}</TextWrapper>
    </StyledButton>
  );
};

export default memo(Button);
