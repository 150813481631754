import * as yup from 'yup';
import { useCallback, useMemo, useState } from 'react';

import { ApprovalStatus } from 'model/approvalStatus';
import { useValidationContext } from 'containers/Common/Validation/ValidationContext';
import { HandleSubmitProps } from './types';

const useLogic = ({
  imgSrc,
  signatureRequired = false,
}: {
  imgSrc: string;
  commentsRequired: boolean;
  signatureRequired: boolean;
}) => {
  const {
    userId,
    accessToken,
    userKeyEncoded,
    formId,
    responseId,
    emailValidatorEncoded,
    transitionTo,
    updateApproval,
    createOrUpdateSignature,
  } = useValidationContext();
  const [errorStatus, setErrorStatus] = useState<string>();
  const [status, setStatus] = useState<ApprovalStatus['status']>();

  const handleSubmitForm: HandleSubmitProps = useCallback(
    async (values, { setFieldError }) => {
      if (!status) {
        setErrorStatus('Approve or reject the file');
        return;
      }

      if (status === 'FEEDBACK' && !values.comments) {
        setFieldError(
          'comments',
          'Please add a comment to request further information',
        );
        return;
      }

      if (values.signature && values.signature !== imgSrc) {
        await createOrUpdateSignature({
          userId,
          signatureAsDataString: values.signature,
          accessToken,
        });
      }

      await updateApproval({
        encodedCreatorKey: userKeyEncoded,
        formId,
        responseId,
        encodedValidatorEmail: emailValidatorEncoded,
        comment: values.comments,
        status,
        hasSigned: signatureRequired && !!values.signature,
        accessToken,
      });

      if (status === 'APPROVED') {
        transitionTo('APPROVED');
      } else if (status === 'REJECTED') {
        transitionTo('REJECTED');
      } else if (status === 'FEEDBACK') {
        transitionTo('FEEDBACK');
      }
    },
    [
      status,
      updateApproval,
      userKeyEncoded,
      formId,
      responseId,
      emailValidatorEncoded,
      createOrUpdateSignature,
      signatureRequired,
      accessToken,
      transitionTo,
      userId,
      imgSrc,
    ],
  );

  const validationSchema = useMemo(() => {
    const comments = yup.string();

    const signature = signatureRequired
      ? yup.string().required('Signature required to proceed')
      : yup.string();

    return yup.object().shape({
      comments,
      signature,
    });
  }, [signatureRequired]);

  return {
    handleSubmitForm,
    status,
    setStatus,
    errorStatus,
    validationSchema,
  };
};
export default useLogic;
