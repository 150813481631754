import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 200px;
  width: fit-content;
`;

export const Title = styled.h1`
  font-size: 26px;
  line-height: 1.3;
  margin: 12px 0 32px 0;
`;

export const Subtitle = styled.p`
  font-size: 20px;
  line-height: 1.3;
  margin: 12px 0 32px 0;
`;

export const Link = styled.a`
  margin-bottom: 14px;
`;

export const ContactMessage = styled.p``;
