export const first = (path: string, levels = 1) =>
  `/${path
    .split('/')
    .filter((it) => it !== '')
    .slice(0, levels)
    .join('/')}`;

export const latest = (path: string, levels = 1) => {
  const subPaths = path.split('/').filter((it) => it !== '');

  const leadingSlash = levels >= subPaths.length ? '/' : '';

  return leadingSlash + subPaths.reverse().slice(0, levels).reverse().join('/');
};

export const split = (path: string) => {
  const leadingSlash = path[0] === '/' ? '' : '/';
  return `${leadingSlash}${path}`.split('/').slice(1);
};

export const up = (path: string, levels = 1) => {
  const subPaths = path.split('/');

  if (levels > subPaths.length) {
    return '/';
  }

  return subPaths.slice(0, subPaths.length - levels).join('/') || '/';
};
