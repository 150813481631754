import type FirebaseType from 'firebase';
import { useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

export type FirebaseAuthProvider =
  | FirebaseType.auth.OAuthProvider
  | FirebaseType.auth.TwitterAuthProvider
  | FirebaseType.auth.FacebookAuthProvider;

export default firebase;

export enum ErrorCodes {
  POPUP_CLOSED_ERROR_CODE = 'auth/popup-closed-by-user',
  ACCOUN_LINKED_WITH_DIFFERENT_CREDENTIALS = 'auth/account-exists-with-different-credential',
  POPUPS_BLOCKED_BY_BROWSER = 'auth/popup-blocked',
  COOKIES_BLOCKED = 'idpiframe_initialization_failed',
  UNKNOWN = 'unknown',
}

const MICROSOFT_PROVIDER_ID = 'microsoft.com';

const supportedPopupSignInMethods = [
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  MICROSOFT_PROVIDER_ID,
];

const fallBackResult = { email: '', token: '' };
let initialUserDataReceived = false;
let initialGoogleUserDataReceived = false;

function getProvider(providerId: string) {
  switch (providerId) {
    case firebase.auth.GoogleAuthProvider.PROVIDER_ID:
      return new firebase.auth.GoogleAuthProvider();
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
      return new firebase.auth.FacebookAuthProvider();
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
      return new firebase.auth.TwitterAuthProvider();
    case MICROSOFT_PROVIDER_ID:
      return new firebase.auth.OAuthProvider(MICROSOFT_PROVIDER_ID);
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
}

function onSignInSuccess(userCredential: FirebaseType.auth.UserCredential) {
  const email = userCredential.user?.email ?? '';
  const { currentUser } = firebase.auth();

  if (!currentUser) {
    return fallBackResult;
  }

  try {
    return currentUser.getIdToken(/* force refresh */ true).then(
      (token) => {
        return { token, email };
      },
      () => fallBackResult,
    );
  } finally {
    // This line is key to the flow, if we don't remove the current user
    // and sign in with an unauthorized account and then sign in with
    // an authorized one we will get an auth/user-mismatch error because
    // we already have a current user saved with different ids.
    // This allows us to have unique sign ins every time
    // TODO: Check for issues with the signOut missing
    // firebase.auth().signOut();
  }
}

/**
 * Google enforces an email per account, so if you first logged in with twitter
 * now the email is linked to twitter only and if you log in now with facebook
 * it will throw the error this function takes care of. So, we check the sign
 * in methods linked to the email, pick the first one, open the popup of that
 * provider with the login hint, obtain the credentials and then link the
 * new provider with the credentials, so now that account will be able to
 * sign in with both providers
 */
async function handleAccountLinkedWithDifferentCredentials(error: any) {
  const providers = await firebase
    .auth()
    .fetchSignInMethodsForEmail(error.email);

  const firstPopupProviderMethod = providers.find((p) =>
    supportedPopupSignInMethods.includes(p),
  );

  if (!firstPopupProviderMethod) {
    throw new Error(
      `Your account is linked to a provider that isn't supported`,
    );
  }

  const linkedProvider = getProvider(firstPopupProviderMethod);
  linkedProvider.setCustomParameters({ login_hint: error.email });

  const result = await firebase.auth().signInWithPopup(linkedProvider);
  if (!result.user) return fallBackResult;

  return result.user
    .linkWithCredential(error.credential)
    .then(onSignInSuccess, () => fallBackResult);
}

function getCredentials() {
  const onSignInError = (error: any) => {
    if (
      error.email &&
      error.credential &&
      error.code === ErrorCodes.ACCOUN_LINKED_WITH_DIFFERENT_CREDENTIALS
    ) {
      return handleAccountLinkedWithDifferentCredentials(error);
    }
    if (error.code === ErrorCodes.POPUPS_BLOCKED_BY_BROWSER) {
      const customError = new Error(ErrorCodes.POPUPS_BLOCKED_BY_BROWSER);
      customError.name = ErrorCodes.POPUPS_BLOCKED_BY_BROWSER;
      throw customError;
    }
    const customError = new Error();
    throw customError;
  };
  return [onSignInSuccess, onSignInError];
}

const setPersistence = (persistence: firebase.auth.Auth.Persistence) =>
  new Promise((resolve, reject) =>
    firebase
      .auth()
      .setPersistence(persistence)
      .then(
        (result) => resolve(result),
        (rejection) => reject(rejection),
      ),
  );

export async function signInWithPopup(provider: FirebaseAuthProvider) {
  provider.setCustomParameters({ display: 'popup' });
  const { currentUser } = firebase.auth();
  await setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  if (currentUser) {
    return currentUser
      .reauthenticateWithPopup(provider)
      .then(...getCredentials());
  }
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(...getCredentials());
}

export async function logout() {
  return firebase.auth().signOut();
}

export function useFirebase(
  initialLoginStateListener: any,
  errorListener: any,
  Google: any,
) {
  const firebaseAppRef = useRef<FirebaseType.app.App>();
  const googleAppRef = useRef();
  useEffect(() => {
    if (googleAppRef.current) return;
    googleAppRef.current = Google;
    Google.listen((state: boolean) => {
      if (state) {
        getCredentials();
      }
      initialGoogleUserDataReceived = true;
      initialLoginStateListener({
        initialGoogleUserDataReceived,
        initialUserDataReceived,
      });
    }, errorListener);
  }, [initialLoginStateListener, Google, errorListener]);
  useEffect(() => {
    if (firebaseAppRef.current) return;
    async function loadFirebase() {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
        messagingSenderId:
          process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
      };
      let app;
      try {
        app = firebase.app();
      } catch {
        app = firebase.initializeApp(firebaseConfig);
      }
      firebaseAppRef.current = app;

      firebase.auth().onAuthStateChanged((user) => {
        if (!initialUserDataReceived) {
          initialUserDataReceived = true;
          if (user) {
            firebase
              .auth()
              .currentUser?.getIdToken()
              .then((token) => {
                const email = firebase.auth().currentUser?.email;
                initialLoginStateListener({
                  initialGoogleUserDataReceived,
                  initialUserDataReceived,
                  userData: { email, token },
                });
              });
          } else {
            initialLoginStateListener({
              initialGoogleUserDataReceived,
              initialUserDataReceived,
            });
          }
        }
      });
    }
    loadFirebase();
  }, [initialLoginStateListener]);
}
