/* eslint-disable no-restricted-globals */
import { Redirect } from 'react-router';
import ValidationResponse from 'components/ValidationResponse';
import Loader from 'components/Loader';
import TopBar from 'components/TopBar';
import ValidationForm from 'components/ValidationForm';
import ValidationLogIn from 'components/ValidationLogIn';
import BlockedPopups from 'containers/Common/BlockedPopups';
import IncognitoMode from 'containers/Common/IncognitoMode';
import { useMemo } from 'react';
import { Container, CardContainer } from './styles';
import { useConnect } from './connect';
import { ValidationProvider } from './ValidationContext';

const Validation = () => {
  const {
    approval,
    updateApproval,
    signature,
    status,
    createOrUpdateSignature,
    hasMandatoryParams,
    userKeyEncoded,
    emailValidatorEncoded,
    displayAwaiting,
    formId,
    userEmail,
    accessToken,
    responseId,
    loaders,
    onLoginWith,
    resetAuthorizationError,
    areTherePopupErrors,
    areCookiesDisabled,
    logout,
  } = useConnect();

  const content = useMemo(() => {
    switch (status) {
      case 'error':
        return <ValidationResponse status="error" />;
      case 'unauthorized':
        return (
          <ValidationResponse
            status="unauthorized"
            currentEmail={userEmail}
            expectedEmail={atob(emailValidatorEncoded)}
            onReset={resetAuthorizationError}
          />
        );
      case 'authenticate':
        return <ValidationLogIn onLoginWith={onLoginWith} loaders={loaders} />;
      case 'pending':
      case 'feedback':
        return (
          <ValidationForm
            filename=""
            formType={displayAwaiting ? 'request-info' : 'regular'}
            needsSignature={approval?.signatureRequired}
            imgSrc={signature?.signatureAsDataString}
          />
        );
      case 'rejected':
        return <ValidationResponse status="rejected" />;
      case 'approved':
        return (
          <ValidationResponse
            status="already-approved"
            mail={approval?.validator}
          />
        );
      default:
      case 'loading':
        return <Loader />;
    }
  }, [
    status,
    approval,
    displayAwaiting,
    signature,
    loaders,
    onLoginWith,
    resetAuthorizationError,
    userEmail,
    emailValidatorEncoded,
  ]);

  if (!hasMandatoryParams) {
    return <Redirect to="/" />;
  }

  if (areTherePopupErrors) {
    return <BlockedPopups />;
  }

  if (areCookiesDisabled) {
    return <IncognitoMode />;
  }

  return (
    <Container>
      {approval?.signatureRequired && (
        <TopBar email={userEmail} name=" " onLogout={logout} skipPlanChange />
      )}
      <CardContainer>
        <ValidationProvider
          userId={userEmail}
          accessToken={accessToken}
          userKeyEncoded={userKeyEncoded}
          formId={formId}
          responseId={responseId}
          emailValidatorEncoded={emailValidatorEncoded}
          updateApproval={updateApproval}
          createOrUpdateSignature={createOrUpdateSignature}
        >
          {content}
        </ValidationProvider>
      </CardContainer>
    </Container>
  );
};

export default Validation;
