import { DomainLicense } from './domainLicense';
import { Stat } from './stat';

export type DomainPlan = 'FREE' | 'PAID';

export type Input = {
  domain: string;
  totalMembers: number;
  lastTimeUsed: string;
  plan: DomainPlan;
  stats: Stat;
  licence: DomainLicense;
};

export const normalizeDomain = (input: Input) => {
  return { ...input } as const;
};

export const isDomainActiveAndPaid = (domain: Domain) => {
  return (
    domain &&
    domain.plan !== 'FREE' &&
    domain.licence &&
    new Date(domain.licence.endTime).getTime() > Date.now()
  );
};

export type Domain = ReturnType<typeof normalizeDomain>;
