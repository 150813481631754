import styled, { css } from 'styled-components';

import { from } from 'styles/media';

import { Props } from './types';

export const IconWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 5rem;
  margin: 0 auto 2rem;
`;

export const MessageCardContainer = styled.div<{
  $variant: Props['variant'];
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.625rem;
  margin: 0 auto;
  max-width: 30rem;
  padding: 3rem 2rem;
  text-align: center;

  ${from.mobile} {
    padding: 5rem;
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'logo':
      default:
        return css`
          ${IconWrapper} {
            border-radius: 0;
          }
        `;
      case 'success':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) => theme.colors.green50};
            color: ${({ theme }) => theme.colors.green700};
          }
        `;
      case 'error':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) => theme.colors.yellow50};
            color: ${({ theme }) => theme.colors.yellow700};
          }
        `;
    }
  }}
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.purple3};
  font-feature-settings: 'tnum' on, 'lnum' on;
  font-size: 0.875rem;
  letter-spacing: -0.0066em;
  line-height: 1.375rem;

  a {
    color: ${({ theme }) => theme.colors.purple4};
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.purple3};
  font-size: 0.75rem;
  letter-spacing: -0.0033em;
  line-height: 1.0625rem;
  margin-top: 1rem;

  a {
    color: ${({ theme }) => theme.colors.purple4};
  }
`;
