import { FC } from 'react';
import { useSortDirection } from '../logic';

import { Container } from './styles';
import { Props } from './types';

const DropdownIndicator: FC<Props> = ({
  className,
  innerProps,
  getValue,
  options,
}) => {
  const isDesc = useSortDirection({ getValue, options });
  return <Container $desc={isDesc} className={className} {...innerProps} />;
};

export default DropdownIndicator;
