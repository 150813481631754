import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { forwardRef } from 'react';

const SlideUp = forwardRef<unknown, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" {...props} ref={ref}>
      {children as any}
    </Slide>
  ),
);

const animations = {
  slideUp: SlideUp,
  fade: Fade,
};

export default animations;

export type AnimationName = keyof typeof animations;
