import { ApprovalStatus } from './approvalStatus';

export type Input = {
  status: ApprovalStatus['status'];
  validator: string;
  signatureRequired: boolean;
};

export const normalizeApproval = (input: Input) => {
  return { ...input };
};

export type Approval = ReturnType<typeof normalizeApproval>;
