import { createClient } from 'data/client';
import { Signature } from 'model/signature';
import { QueryFunctionContext } from 'react-query';
import { imageUrlToDataUrl } from 'utils/imageUrlToDataUrl';

export async function getSignature({ queryKey }: QueryFunctionContext) {
  const [, { userId, accessToken }] = queryKey as [
    string,
    { userId: string; accessToken: string },
  ];
  const client = createClient();
  const { data } = await client.get<Signature>(`/users/${userId}/signature`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  let signatureAsDataString = '';
  if (data.signatureUrl) {
    const dataUrl = await imageUrlToDataUrl(data.signatureUrl);
    if (typeof dataUrl === 'string') {
      signatureAsDataString = dataUrl;
    }
  }
  return { ...data, signatureAsDataString };
}

export async function putSignature({
  userId,
  signatureImage,
  accessToken,
}: {
  userId: string;
  signatureImage: string;
  accessToken: string;
}) {
  const client = createClient();
  return client.put(
    `/users/${userId}/signature`,
    { content: signatureImage },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
}

// This request is not used for now
export async function deleteSignature({ userId }: { userId: string }) {
  const client = createClient();
  return client.delete(`/users/${userId}/signature`);
}
