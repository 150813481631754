import { getCustomerPortal } from 'data/requests/customerPortal';
import { normalizeCustomerPortal } from 'model/customerPortal';
import { useQuery } from 'react-query';
import { RetryQueryError } from 'model/retryQueryError';

export const useCustomerPortal = ({
  userId,
  returnUrl,
}: {
  userId?: string;
  returnUrl: string;
}) => {
  const { data, status } = useQuery(
    ['customer-portal', { userId, returnUrl }],
    getCustomerPortal,
    {
      enabled: !!userId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error: RetryQueryError) => {
        if (error.response?.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );
  const customerPortal = data && normalizeCustomerPortal(data);
  return { customerPortal, status };
};
