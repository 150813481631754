import { RefObject, useEffect, useState } from 'react';

interface Dimensions {
  top: number;
  bottom: number;
  height: number;
  width: number;
  x: number;
  y: number;
}

const initialValues = {
  top: 0,
  bottom: 0,
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

const useElementDimensions = (ref: RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState<Dimensions>(initialValues);

  useEffect(() => {
    const measure = () => {
      setDimensions(ref.current?.getBoundingClientRect() ?? initialValues);
    };

    measure();

    window.addEventListener('resize', measure);
    window.addEventListener('scroll', measure);

    return () => {
      window.removeEventListener('resize', measure);
      window.removeEventListener('scroll', measure);
    };
  }, [ref]);

  return dimensions;
};

export default useElementDimensions;
