// eslint-disable-next-line import/no-cycle
import { client } from 'data/client';
import { QueryFunctionContext } from 'react-query';
import { Cannon } from '../../model/cannon';

export async function getCannon({ queryKey }: QueryFunctionContext) {
  const [, { project, placement }] = queryKey as [
    string,
    { project: string; placement: string },
  ];
  const { data } = await client.get<Cannon>('/cannon', {
    params: { project, placement },
  });
  return data;
}
