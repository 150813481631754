import { useMediaQuery } from '@material-ui/core';

import MessageCard from 'components/MessageCard';

import { from } from 'styles/media';
import StaticLayout from 'containers/Common/StaticLayout';
import GoogleButton from 'components/GoogleButton';
import Loader from 'components/Loader';
import testingVar from 'utils/testingVar';
import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Error from './Error';
import Success from './Success';
import { useConnect } from './connect';
import {
  Text,
  Error as ErrorMessage,
  ConfirmButton,
  LoginMessage,
  Centered,
} from './styles';

const StopAutomaticRenewal = () => {
  const { clientId, onLogin, cancelRenewal, error, status } = useConnect();

  const isMobile = !useMediaQuery(from.mobile);

  const textContent = useMemo(
    () => (
      <>
        This action is permanent, if you wish to re-enable the automatic
        renewal, you have to purchase a new plan from our{' '}
        <a
          href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Pricing page
        </a>
      </>
    ),
    [],
  );

  const subtitleContent = useMemo(() => {
    switch (status) {
      case 'error':
        return <ErrorMessage>{error}</ErrorMessage>;
      case 'unauthenticated': {
        return (
          <>
            <LoginMessage data-test-id={testingVar('login')}>
              Sign in with your Google account to stop the automatic renewal.
            </LoginMessage>
            <GoogleButton
              onClick={() => onLogin()}
              data-test-id={testingVar('submit-login')}
            />
          </>
        );
      }
      case 'authenticated': {
        return (
          <ConfirmButton
            onClick={cancelRenewal}
            data-test-id={testingVar('confirm-cancellation')}
          >
            Confirm cancellation
          </ConfirmButton>
        );
      }
      default:
      case 'loading':
        return <Loader size={36} />;
    }
  }, [status, cancelRenewal, clientId, error, onLogin]);

  if (status === 'unauthorized') {
    return <Redirect to="/" />;
  }

  if (status === 'unsuccess') {
    return <Error />;
  }

  if (status === 'success') {
    return <Success />;
  }

  return (
    <StaticLayout data-test-id={testingVar('stop-automatic-renewal')}>
      <MessageCard
        title="Are you sure you want to stop the automatic renewal?"
        subtitle={<Centered>{subtitleContent}</Centered>}
        text={isMobile ? '' : textContent}
      />
      {isMobile && <Text>{textContent}</Text>}
    </StaticLayout>
  );
};

export default StopAutomaticRenewal;
