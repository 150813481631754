import { captureException } from '@sentry/react';
import { sendLog } from 'utils/logToStackdriver';

export const logErrorLoginRequest = async (
  error?: string,
  error_description?: string,
) => {
  try {
    await sendLog({
      timestamp: Date.now(),
      message: 'Webapp login failed',
      event_type: 'webapp_login_request',
      error,
      error_description,
    });
  } catch (err) {
    captureException(`${error} / ${error_description}`);
    captureException(err);
  }
};

export const logSuccessLoginRequest = async (code: string) => {
  try {
    await sendLog({
      code,
      timestamp: Date.now(),
      message: 'Webapp login success',
      event_type: 'webapp_login_request',
    });
  } catch (err) {
    captureException(err);
  }
};

export const logSolvayMigrationUser = async (
  validatorName?: string,
  userName?: string,
) => {
  try {
    await sendLog({
      timestamp: Date.now(),
      message: `Validation allowed for Solvay user ${validatorName}`,
      event_type: 'solvay_user_validation',
      validatorName,
      userName,
    });
  } catch (err) {
    captureException(err);
  }
};
