import styled, { css, keyframes } from 'styled-components';
import DefaultButton from 'components/Button';
import { ReactComponent as DefaultFileIcon } from 'assets/images/icons/file.svg';
import { ReactComponent as ErrorCircleIcon } from 'assets/images/icons/error-circle.svg';
import { ReactComponent as SuccessCircleIcon } from 'assets/images/icons/success-circle.svg';
import { ReactComponent as WarningCircleIcon } from 'assets/images/icons/warning-circle.svg';
import { from } from 'styles/media';

export const slideUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(1.25rem);
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }`;

export const reveal = keyframes`
         from {
          stroke-dashoffset: 100;
      }
      to {
          stroke-dashoffset: 0;
      }`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  animation: ${slideUp} 0.5s ease-out forwards;
  position: relative;
  margin: 0 auto 5rem;
  padding: 2rem;
  width: 100%;
  max-width: 30rem;

  ${from.mobile} {
    padding: 3rem 4rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.purple4};
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  text-align: center;
  margin-bottom: 0.5rem;
  animation: ${slideUp} 0.5s ease-out forwards;
`;
export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.66%;
  color: ${({ theme }) => theme.colors.purple3};
  text-align: center;
  animation: ${slideUp} 0.5s ease-out forwards;
`;

export const IconContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  animation: ${slideUp} 0.5s ease-out forwards;
`;
export const FileIcon = styled(DefaultFileIcon)`
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`;

export const AnimatedIcon = css`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  & line,
  & path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: ${reveal} 1.5s ease-out forwards;
    fill: none;
    animation-delay: 0.3s;
  }
`;

export const RejectedIcon = styled(ErrorCircleIcon)`
  ${AnimatedIcon}
`;
export const ApprovedIcon = styled(SuccessCircleIcon)`
  ${AnimatedIcon}
`;

export const WarningIcon = styled(WarningCircleIcon)`
  margin-bottom: 2rem;
`;
export const CheckCircleIcon = styled(SuccessCircleIcon)`
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
  circle {
    fill: ${({ theme }) => theme.colors.green50};
  }
  path {
    stroke: ${({ theme }) => theme.colors.green700};
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: ${reveal} 1.5s ease-out forwards;
    fill: none;
    animation-delay: 0.3s;
  }
`;

export const Button = styled(DefaultButton)`
  margin-top: 2rem;
  animation: ${slideUp} 0.5s ease-out forwards;
`;
