import Bold from 'components/Bold';
import { Column } from 'components/DesktopTable/types';
import { User } from 'model/user';
import { formatShortMonthDayYear } from 'utils/dates';
import TextWrap from 'components/TextWrap';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { Avatar } from '../styles';

export const getColumns = () =>
  [
    {
      Header: 'Name',
      accessor: 'name',
      width: 270,
      Cell: ({ value, row }) => {
        const { photo, initials } = row.original as never as User;
        return (
          <>
            <Avatar src={photo} initials={initials} />
            <TextWrap title={value.length > 24 ? value : undefined}>
              <Bold>{value}</Bold>
            </TextWrap>
          </>
        );
      },
    },
    {
      Header: 'Last File',
      accessor: 'lastGeneratedFileDate',
      width: 134,
      filter: 'unix',
      sortType: 'datetime',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatShortMonthDayYear} />
      ),
    },
    {
      Header: 'Files this month',
      accessor: 'stats.totalGeneratedThisMonth',
      alignment: 'right',
      width: 150,
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
      ),
    },
    {
      Header: 'Files all time',
      accessor: 'stats.totalFilesGenerated',
      alignment: 'right',
      width: 150,
      Cell: ({ value }) => (
        <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
      ),
    },
  ] as Column[];
