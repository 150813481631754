import testingVar from 'utils/testingVar';
import { Title, Text, IconContainer, FileIcon, ApprovedIcon } from '../styles';

const ApprovedCard = () => {
  return (
    <>
      <IconContainer>
        <FileIcon />
        <ApprovedIcon />
      </IconContainer>
      <Title data-test-id={testingVar('validation-workflow-approved')}>
        The file has been approved
      </Title>
      <Text>Thanks for using Form Publisher</Text>
    </>
  );
};

export default ApprovedCard;
