import { useQuery } from 'react-query';
import { getCannon } from '../requests/getCannon';

export function useCannon(placement: string): any {
  const { data, ...rest } = useQuery(
    ['cannon', { project: 'webapp', placement }],
    getCannon,
    {
      enabled: false, // until needed
      refetchOnMount: false,
      retry: false,
    },
  );
  return { cannon: data, ...rest };
}
