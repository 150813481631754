import { FC, memo } from 'react';
import { StyledSelect } from './styles';

import DropdownIndicator from './DropdownIndicator';
import Option from './Option';

import { Props } from './types';
import { useAdjustSelectWidth } from './logic';

const selectComponents = { DropdownIndicator, Option };

const Selector: FC<Props> = ({
  options,
  placeholder,
  value,
  onChange,
  defaultValue,
  ...props
}) => {
  useAdjustSelectWidth({ value, defaultValue });
  return (
    <StyledSelect
      components={selectComponents}
      value={value}
      onChange={onChange}
      isClearable={false}
      isLoading={false}
      isMulti={false}
      isSearchable={false}
      options={options}
      placeholder="Sort by"
      classNamePrefix="react-select-sorter"
      defaultValue={defaultValue}
      {...props}
    />
  );
};

export default memo(Selector);
