import { FC } from 'react';
import AlreadyApprovedCard from './AlreadyApprovedCard';
import ApprovedCard from './ApprovedCard';
import RejectedCard from './RejectedCard';
import ErrorCard from './ErrorCard';
import InfoRequestedCard from './InfoRequested';
import { Card } from './styles';
import { Props } from './types';
import UnauthorizedCard from './UnauthorizedCard';

const ValidationResponse: FC<Props> = ({
  status,
  mail,
  onReset,
  currentEmail,
  expectedEmail,
}) => {
  const handleRendering = () => {
    switch (status) {
      case 'approved':
        return <ApprovedCard />;
      case 'rejected':
        return <RejectedCard />;
      case 'already-approved':
        return <AlreadyApprovedCard mail={mail} />;
      case 'information-requested':
        return <InfoRequestedCard />;
      case 'unauthorized':
        return (
          <UnauthorizedCard
            onReset={onReset}
            currentEmail={currentEmail}
            expectedEmail={expectedEmail}
          />
        );
      default:
      case 'error':
        return <ErrorCard />;
    }
  };
  return <Card>{handleRendering()}</Card>;
};

export default ValidationResponse;
