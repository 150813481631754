import { queryClient } from 'data/queryClient';
import { GoogleProfileQuery } from 'data/hooks/useGoogleProfile';
import * as googleAuthClient from './google-axios-client';
import { Token } from './token';
import {
  RefreshTokens,
  CodeTokens,
  UserInfo,
  AuthenticatedUser,
} from './types';

let user: AuthenticatedUser | undefined;

function getUserData() {
  return user;
}

function setUser(userInfo?: UserInfo) {
  if (userInfo) {
    user = {
      name: userInfo.name,
      email: userInfo.email,
      imageUrl: userInfo.picture,
    };
  }
}

async function getAndSetUserInfo(
  accessToken: string,
): Promise<AuthenticatedUser | undefined> {
  const response = await googleAuthClient.getUserInfo(accessToken);
  if (response?.data) {
    setUser(response?.data);
    return user;
  }
  return undefined;
}

function getAuthData() {
  if (!user || !user.email) return null;
  return {
    user,
  };
}

function saveProfileData(
  profile: NonNullable<ReturnType<typeof getAuthData>>['user'],
) {
  queryClient.setQueryData<GoogleProfileQuery>('googleProfile', profile);
}

async function refreshToken(
  backendIdToken: string,
): Promise<RefreshTokens | undefined> {
  const { sessionId } = Token.get();
  if (sessionId) {
    const response = await googleAuthClient.refreshToken(
      backendIdToken,
      sessionId,
    );
    if (response?.data) {
      return response?.data as RefreshTokens;
    }
  }
  return undefined;
}

async function getTokens(code: string): Promise<CodeTokens | undefined> {
  const response = await googleAuthClient.authenticate(code);
  if (response?.data) {
    return response?.data as CodeTokens;
  }
  return undefined;
}

async function refreshAuth(): Promise<boolean> {
  const tokens = Token.get();
  if (!tokens?.backendIdToken) {
    return false;
  }
  const newTokens = await refreshToken(tokens.backendIdToken);
  if (!newTokens) {
    return false;
  }
  const expirationInSeconds = Math.round(Date.now() / 1000);
  Token.set({
    token: newTokens.accessToken,
    idToken: newTokens.idToken,
    sessionId: newTokens.sessionId,
    expiresAt: (expirationInSeconds + newTokens.expiresAt) * 1000,
  });
  return true;
}

async function getScopes(): Promise<string[] | undefined> {
  const tokens = Token.get();
  if (tokens.token) {
    const response = await googleAuthClient.getTokenInfo(tokens.token);
    return response?.data?.scope?.split(' ');
  }
  return undefined;
}

async function signOut() {
  const { backendIdToken, sessionId } = Token.get();
  if (backendIdToken && sessionId) {
    await googleAuthClient.revokeToken(backendIdToken, sessionId);
  }
}

export const Google = {
  user,
  getScopes,
  refreshToken,
  getTokens,
  setUser,
  refreshAuth,
  getUserData,
  getAndSetUserInfo,
  saveProfileData,
  signOut,
};
