import styled from 'styled-components';
import { from } from 'styles/media';

export const Container = styled.div`
  display: block;

  ${from.tablet} {
    display: flex;
    min-height: 100vh;
  }
`;

export const Main = styled.div`
  flex: 1;
`;
