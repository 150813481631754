import { client } from 'data/client';
import { Input } from 'model/domain';
import { QueryFunctionContext } from 'react-query';

type GetCampaignResponse = Input;

export async function getDomain({ queryKey }: QueryFunctionContext) {
  const [, { userId, includeStats }] = queryKey as [
    string,
    {
      userId: string;
      includeStats?: boolean;
    },
  ];
  const { data } = await client.get<GetCampaignResponse>(
    `/users/${userId}/domain`,
    {
      params: {
        includeStats,
      },
    },
  );
  return data;
}
