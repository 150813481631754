import firebase, { signInWithPopup } from '../useFirebase';

async function loginWithTwitter() {
  const twitterProvider = new firebase.auth.TwitterAuthProvider();
  return signInWithPopup(twitterProvider);
}

export default {
  loader: async () => {},
  initializer: async () => {},
  login: loginWithTwitter,
};
