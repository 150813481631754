/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { Token } from './context/AuthContext/token';

export const createClient = (config?: AxiosRequestConfig) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE}`,
    ...config,
  });

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE}`,
});

client.interceptors.request.use(async (config) => {
  const { token } = Token.get();
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
