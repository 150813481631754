import styled from 'styled-components';

import addAlpha from 'utils/addAlpha';

export const Container = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 0.875rem 0.75rem;
  row-gap: 0.3125rem;
  text-decoration: none;
  :not(:last-child) {
    border-bottom: 0.0625rem solid
      ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
  }
`;
