/* eslint-disable react/no-unused-prop-types */
import { FC } from 'react';
import { ReactComponent as RejectIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import Textarea from 'components/Textarea';
import ValidationButton from 'components/ValidationButton';
import { Formik } from 'formik';
import testingVar from 'utils/testingVar';
import { Error } from 'components/Textarea/styles';
import { Title, Form, ButtonsRow, SignatureInput } from '../styles';
import useLogic from '../logic';
import { FormProps } from '../types';

const RegularForm: FC<FormProps> = ({ filename, imgSrc, needsSignature }) => {
  const { status, setStatus, errorStatus, handleSubmitForm, validationSchema } =
    useLogic({
      imgSrc: imgSrc ?? '',
      commentsRequired: false,
      signatureRequired: !!needsSignature,
    });

  return (
    <>
      <Title data-test-id="validation-workflow">
        You have been asked to approve the {filename} file
      </Title>
      <Formik
        initialValues={{ comments: '', signature: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              {needsSignature && (
                <SignatureInput
                  name="signature"
                  label="Add your signature"
                  imgSrc={imgSrc}
                  data-test-id="validation-workflow-signature-input"
                />
              )}
              <Textarea
                label="Add comments"
                name="comments"
                placeholder="Leave your comments..."
                data-test-id={testingVar(
                  'validation-workflow-comments-textarea',
                )}
                isOptional
              />
              <ButtonsRow>
                <ValidationButton
                  type="submit"
                  color="primary"
                  variant="outlined"
                  icon={<RejectIcon />}
                  fullWidth
                  loading={status === 'REJECTED' && isSubmitting}
                  disabled={status !== 'REJECTED' && isSubmitting}
                  data-test-id={testingVar('validation-workflow-reject-button')}
                  onClick={() => {
                    setStatus('REJECTED');
                  }}
                >
                  Reject
                </ValidationButton>
                <ValidationButton
                  type="submit"
                  color="secondary"
                  variant="outlined"
                  icon={<CheckIcon />}
                  fullWidth
                  loading={status === 'APPROVED' && isSubmitting}
                  disabled={status !== 'APPROVED' && isSubmitting}
                  data-test-id={testingVar(
                    'validation-workflow-approve-button',
                  )}
                  onClick={() => {
                    setStatus('APPROVED');
                  }}
                >
                  Approve
                </ValidationButton>
              </ButtonsRow>
              {errorStatus && <Error>{errorStatus}</Error>}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RegularForm;
