import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';

import { Props } from './types';

export const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => addAlpha(theme.colors.purple2, 0.75)};
  border-radius: 0.9375rem;
  height: 0.25rem;
  width: 100%;
`;

export const Progress = styled.div.attrs(({ progress }: Props) => ({
  style: {
    width: `${progress}%`,
  },
}))<Props>`
  border-radius: 0.9375rem;
  height: 100%;
  transition: width 250ms ease-out;

  ${({ theme, variant }) => {
    switch (variant) {
      case 'purple':
        return css`
          background-color: ${theme.colors.purple};
        `;

      case 'green':
        return css`
          background-color: ${theme.colors.green700};
        `;

      case 'yellow':
        return css`
          background-color: ${theme.colors.yellow700};
        `;

      default:
      case 'red':
        return css`
          background-color: ${theme.colors.red600};
        `;
    }
  }};
`;
