export default {
  fonts: {
    inter: 'Inter',
    lexendDeca: 'Lexend Deca',
    roboto: 'Roboto',
  },
  colors: {
    // Gray scale
    gray350: 'rgba(85, 80, 78, 0.5)',
    gray325: 'rgba(85, 80, 78, 0.25)',
    gray225: 'rgba(222, 220, 219, 0.25)',
    gray250: 'rgba(222, 220, 219, 0.5)',
    gray1: '#F9F7F5',
    gray2: 'rgba(222, 220, 219)',
    gray3: '#55504E',
    gray4: '#242221',
    black: '#00000',

    // White
    white: '#FFFFFF',
    white75: 'rgba(255, 255, 255, 0.75)',
    white50: 'rgba(255, 255, 255, 0.5)',

    // Primary FP
    orangeDark2: '#B0840E',
    orangeDark1: '#D6A011',
    orange: '#FCBC14',
    orangeLight1: '#FFC834',
    orangeLight2: '#FFD355',
    orangeLight3: '#FFDD77',

    blue950: '#1A4159',

    // Secondary FP
    purple: '#6246E4',
    purpleDark1: '#533CC2',
    purpleDark2: '#4531A0',
    purpleLight1: '#7860EA',
    purpleLight2: '#8F7AF0',
    purpleLight3: '#A695F5',
    purpleLight: '#F1EEFF',
    purple1: '#F6F5FA',
    purple2: '#DCD6EF',
    purple225: 'rgba(220, 214, 239, 0.25)',
    purple250: 'rgba(220, 214, 239, 0.5)',
    purple3: '#5E5588',
    purple350: 'rgba(94, 85, 136, 0.5)',
    purple375: 'rgba(94, 85, 136, 0.75)',
    purple4: '#271B5C',
    purple450: 'rgba(39, 27, 92, 0.5)',
    purple475: 'rgba(39, 27, 92, 0.75)',
    purpleSidebar: '#4F4585',

    // Success
    green50: '#E4F6EE',
    green100: '#BDE9D4',
    green400: '#20C289',
    green500: '#13AB75',
    green600: '#0C9C6A',
    green700: '#00955B',

    // Warning
    yellow50: '#FDF7E3',
    yellow100: '#FAEAB7',
    yellow400: '#F2C641',
    yellow700: '#EE9E2A',

    // Error
    red50: '#FFECEF',
    red400: '#F35759',
    red500: '#F84641',
    red600: '#EA3E40',

    // Social buttons
    googleButton: '#4285F4',
    facebookButton: '#4285F4',
    facebookButton100: '#2B76F3',
    facebookButton200: '#1C6DF2',
    twitterButton: '#00ACEE',
    twitterButton100: '#00A2E0',
    twitterButton200: '#009BD6',
    microsoftButton: '#2F2F2F',
    microsoftButton100: '#212121',
    microsoftButton200: '#1A1A1A',
  },
  weights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
