import { isValid } from 'date-fns';
import { TimeHTMLAttributes } from 'react';

export function safeTimeElementProps(date: Date) {
  const timeElementProps: Pick<
    TimeHTMLAttributes<HTMLTimeElement>,
    'dateTime' | 'title'
  > = {};

  if (isValid(date)) {
    timeElementProps.dateTime = date.toISOString();
    timeElementProps.title = date.toLocaleString();
  }

  return timeElementProps;
}
