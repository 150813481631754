import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { ReactComponent as DefaultPositiveLogo } from 'assets/images/brand/form-publisher-logo-positive.svg';
import { ReactComponent as DefaultNegativeLogo } from 'assets/images/brand/form-publisher-logo-negative.svg';
import { TitleProps } from './types';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const PositiveLogo = styled(DefaultPositiveLogo)`
  margin-right: 1rem;
`;
export const NegativeLogo = styled(DefaultNegativeLogo)`
  margin-right: 1rem;
`;

export const Title = styled.p<TitleProps>`
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme, $inverse }) =>
    $inverse ? theme.colors.white : theme.colors.purple};
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  width: max-content;
`;
