import StatsCard from 'components/StatsCard';
import { from, useMediaQuery } from 'styles/media';
import DataView from 'components/DataView';
import MobileTable from 'components/MobileTable';
import UserRow from 'components/MobileTable/UserRow';
import testingVar from 'utils/testingVar';
import { Redirect } from 'react-router';
import { Container, Title, StatsContainer } from '../Dashboard/styles';
import { DesktopTable, Header, Subtitle } from './styles';
import { useConnect } from './connect';
import Empty from './table/empty';
import NotFound from './table/notFound';
import Exporter from './table/exporter';
import Search from './table/filter';

const TeamReport = () => {
  const { me, domain, columns, users, status } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);

  if (me && me.plan !== 'DOMAIN_PAID') return <Redirect to="/" />;

  return (
    <Container>
      <Header>
        <Title>Team Report</Title>
        <Subtitle>
          This lists anyone who has used Form Publisher in the domain{' '}
          <a href={`https://${domain?.domain}`}>{domain?.domain}</a>
        </Subtitle>
      </Header>
      <StatsContainer>
        <StatsCard
          variant="team"
          number={domain?.totalMembers}
          text="Active users"
          data-test-id={testingVar('active-users')}
        />
        <StatsCard
          variant="files30"
          number={domain?.stats.filesGeneratedThisMonth}
          text="Files generated this month"
          data-test-id={testingVar('files-30')}
        />
        <StatsCard
          variant="files"
          number={domain?.stats.totalFilesGenerated}
          text="Files generated all time"
          data-test-id={testingVar('files-all')}
        />
      </StatsContainer>
      <DataView>
        {isMobile ? (
          <MobileTable
            data={users}
            columns={columns}
            loading={status === 'loading'}
            row={(item) => (
              <UserRow
                key={item.email}
                name={item.name}
                initials={item.initials}
                photo={item.photo}
                date={item.lastGeneratedFileDate}
                sentMonthly={item.stats.totalGeneratedThisMonth}
                sentAll={item.stats.totalFilesGenerated}
              />
            )}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        ) : (
          <DesktopTable
            data={users}
            loading={status === 'loading'}
            columns={columns}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
            disableFilter
          />
        )}
      </DataView>
    </Container>
  );
};

export default TeamReport;
