import { useAuth } from 'data/context/AuthContext';
import { useCancelAutoRenewal } from 'data/hooks/useCancelAutoRenewal';
import { useDomain } from 'data/hooks/useDomain';
import { useMe } from 'data/hooks/useMe';
import { useUser } from 'data/hooks/useUser';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import { State } from './types';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const useConnect = () => {
  const { token, onLogin, error } = useAuth();
  const { me: emailMe } = useMe({ keepPreviousData: true });
  const meEmail = emailMe?.email;
  const { domain, status: domainPlanStatus } = useDomain(
    { userId: meEmail, includeStats: false },
    { keepPreviousData: true },
  );
  const { user: me, status: getMeStatus } = useUser('me');
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const query = useQueryParams();
  const [isLoading, setIsLoading] = useState(false);
  const commitCancelRenewal = useCancelAutoRenewal();
  const success = query.get('success');
  const amOwnerOfDomainPlan = domain?.licence?.emailUsedToPurchase === meEmail;
  let subscriptionId: string | undefined;
  if (me && 'license' in me) {
    subscriptionId = me.license.subscriptionId;
  } else if (domain && amOwnerOfDomainPlan) {
    subscriptionId = domain.licence.subscriptionId;
  }
  const isPaypalUser =
    me && 'license' in me && me.license.paymentSource === 'PAYPAL';

  const status: State = useMemo(() => {
    if (
      getMeStatus === 'loading' ||
      isLoading ||
      domainPlanStatus === 'loading'
    ) {
      return 'loading';
    }
    if (error) return 'error';
    if (!isPaypalUser && getMeStatus === 'success' && !amOwnerOfDomainPlan) {
      return 'unauthorized';
    }
    if (!token && clientId) return 'unauthenticated';
    if (token && success === '0') return 'unsuccess';
    if (token && success === '1') return 'success';
    if (token) return 'authenticated';
    return 'loading';
  }, [
    error,
    token,
    success,
    getMeStatus,
    isPaypalUser,
    isLoading,
    domainPlanStatus,
    amOwnerOfDomainPlan,
  ]);

  const cancelRenewal = useCallback(async () => {
    setIsLoading(true);
    try {
      await commitCancelRenewal({ subscriptionId });
      push(`${url}?success=1`);
    } catch (e) {
      push(`${url}?success=0`);
    } finally {
      setIsLoading(false);
    }
  }, [commitCancelRenewal, push, url, subscriptionId]);

  return {
    isLoggedIn: !!token,
    clientId,
    onLogin,
    error,
    cancelRenewal,
    success,
    isPaypalUser,
    status,
    domain,
  };
};
