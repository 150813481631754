import { FC } from 'react';
import { Title, Text, WarningIcon } from '../styles';
import Props from './types';

const AlreadyApprovedCard: FC<Props> = ({ mail }) => {
  return (
    <>
      <WarningIcon />
      <Title data-test-id="validation-workflow-already-approved">
        Sorry, {mail || 'someone'} has already approved this file
      </Title>
      <Text>Thanks for using Form Publisher</Text>
    </>
  );
};

export default AlreadyApprovedCard;
