import axios from 'axios';
import { captureException } from '@sentry/minimal';
import { GoogleScopesResponse, GoogleUserInfo } from './types';

async function authenticate(code: string): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_GOOGLE_AUTH_URL}/v1/auth/token`,
      {
        code,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    captureException(error);
  }
  return res;
}

async function refreshToken(
  backendIdToken: string,
  sessionId: string,
): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_GOOGLE_AUTH_URL}/v1/auth/refresh-token`,
      {
        sessionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${backendIdToken}`,
        },
      },
    );
  } catch (error) {
    captureException(error);
  }
  return res;
}

async function revokeToken(
  backendIdToken: string,
  sessionId: string,
): Promise<any> {
  let res;
  try {
    res = await axios.post(
      `${process.env.REACT_APP_GOOGLE_AUTH_URL}/v1/auth/revoke`,
      {
        sessionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${backendIdToken}`,
        },
      },
    );
  } catch (error) {
    captureException(error);
  }
  return res;
}

async function getTokenInfo(
  accessToken: string,
): Promise<GoogleScopesResponse | undefined> {
  let res;
  try {
    res = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    captureException(error);
  }
  return res;
}

async function getUserInfo(
  accessToken: string,
): Promise<GoogleUserInfo | undefined> {
  let res;
  try {
    res = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    captureException(error);
  }
  return res;
}

export { authenticate, refreshToken, revokeToken, getTokenInfo, getUserInfo };
