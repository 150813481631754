import { FC, memo } from 'react';
import { Props } from './types';
import { Container, Loader } from './styles';

const LoadingScreen: FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Loader size={50} thickness={3} />
    </Container>
  );
};

export default memo(LoadingScreen);
