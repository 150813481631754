import { FC } from 'react';
import Container from 'components/Container';
import { Props } from './types';

import { Header, Brand, Logo, Main } from './styles';

const StaticLayout: FC<Props> = ({ children, ...props }) => {
  return (
    <>
      <Header {...props}>
        <Brand to="/">
          <Logo />
        </Brand>
      </Header>
      <Main>
        <Container>{children}</Container>
      </Main>
    </>
  );
};

export default StaticLayout;
