import Cookies from 'js-cookie';
import { addDays } from 'date-fns';
import { CookiesOptions } from '../cookiesUtils';

export const AuthCookie = {
  set() {
    Cookies.set('auth', 'true', {
      ...CookiesOptions,
      expires: addDays(new Date(), 36),
    });
  },
  remove() {
    Cookies.remove('auth', { ...CookiesOptions });
  },
  isLoggedIn(): boolean {
    return Cookies.get('auth') === 'true';
  },
  getAuthUserIndex(): number {
    const cookieValue = Cookies.get('G_AUTHUSER_H') || '0';
    const value = parseInt(cookieValue, 10);
    if (Number.isNaN(value)) {
      return 0;
    }

    return value;
  },
};
