import { useForms } from 'data/hooks/useForms';
import { useMe } from 'data/hooks/useMe';
import { useRouteMatch } from 'react-router-dom';

export const useConnect = () => {
  const { url, path } = useRouteMatch();
  const { me } = useMe();
  const userId = me?.email;
  const stats = me?.stats;
  const { forms, status } = useForms(userId);

  const isLoading = status === 'loading' || !userId;

  return {
    url,
    path,
    forms,
    isLoading,
    stats,
  };
};
