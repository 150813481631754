import MessageCard from 'components/MessageCard';
import { Link } from 'react-router-dom';

import StaticLayout from 'containers/Common/StaticLayout';

const Error = () => {
  return (
    <StaticLayout>
      <MessageCard
        variant="error"
        title="Sorry, an error ocurred"
        subtitle={
          <>
            Please try again or visit our{' '}
            <Link
              to={process.env.REACT_APP_HELP_CENTER_URL}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Help center
            </Link>
            .
          </>
        }
      />
    </StaticLayout>
  );
};

export default Error;
