import styled, { css } from 'styled-components';

import { ReactComponent as DownArrow } from 'assets/images/icons/arrow-down-small.svg';

export const Container = styled.div<{ isSelected: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.purple3};
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 0.75rem;

  &:hover {
    background: ${({ theme }) => theme.colors.purple1};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.purple4};
      font-weight: 500;
    `};
`;

export const DownIcon = styled(DownArrow)`
  margin-left: 0.3125rem;
`;

export const UpIcon = styled(DownArrow)`
  margin-left: 0.3125rem;
  transform: rotate(180deg);
`;
