import styled from 'styled-components';
import addAlpha from 'utils/addAlpha';
import { NavLink } from 'react-router-dom';

export const Container = styled.nav`
  width: 16rem;
  background-color: ${({ theme }) => theme.colors.purpleSidebar};
  align-self: flex-start;
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopSection = styled.div`
  padding: 0 2rem;

  & > * {
    padding: 1.25rem 0;
  }
`;

export const BottomSection = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.white, 0.25)};
  margin-bottom: 0.9375rem;
  min-height: 4.6875rem;
`;

export const Navigation = styled.ul`
  > * + * {
    margin-top: 1.375rem;
  }
`;

export const ItemIcon = styled.div`
  margin-right: 1.375rem;
`;

export const Item = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  text-decoration: none;
  padding-left: 0.375rem;
  font-weight: ${({ theme }) => theme.weights.medium};

  &.active {
    opacity: 1;

    > ${ItemIcon} {
      color: ${({ theme }) => theme.colors.orangeLight2};
    }
  }
`;
