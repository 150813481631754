import { getMe } from 'data/requests/me';
import { normalizeMe } from 'model/me';
import { UseQueryOptions, useQuery } from 'react-query';
import { Input as User } from 'model/user';
import { useGoogleProfile } from './useGoogleProfile';

export function useMe(options?: UseQueryOptions<User>): any {
  const { data, ...rest } = useQuery('me', getMe, {
    refetchOnMount: false,
    ...options,
  });
  const { data: profileData } = useGoogleProfile();

  let mergedProfile: User | undefined = data ? { ...data } : undefined;

  if (data && profileData && (!data.email || !data.fullName || !data.picture)) {
    mergedProfile = {
      ...data,
      fullName: profileData.name,
      email: profileData.email,
      picture: profileData.imageUrl,
    };
  }

  // Users that are registered within YAMM but have no
  // activity return its email as null@null so, we should
  // set the email field to undefined, in order to prevent
  // undesired requests. The merged profile would fix itself
  // with google's basic profile data
  if (mergedProfile?.email === 'null@null') {
    mergedProfile = undefined;
  }

  const me = data && mergedProfile && normalizeMe(mergedProfile);
  return { me, ...rest };
}
