import { FC, memo } from 'react';
import { format12hTime, formatMonthDay } from 'utils/dates';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { Props } from './types';
import { Column, Datetime, Name, InfoItem, Info, Time } from '../styles';
import { Container } from './styles';

const Row: FC<Props> = ({ name, generated, date, time, id, ...props }) => {
  return (
    <Container {...props}>
      <Column>
        <Name>
          <a
            rel="noreferer"
            target="blank"
            href={`https://docs.google.com/forms/d/${id}/edit`}
          >
            {name}
          </a>
        </Name>
        <Info>
          <InfoItem>{generated} file</InfoItem>
        </Info>
      </Column>
      <Column>
        <Datetime>
          {date ? (
            <DateTimeCell value={date} formatFunction={formatMonthDay} />
          ) : null}
          {time ? (
            <Time>
              <DateTimeCell value={time} formatFunction={format12hTime} />
            </Time>
          ) : null}
        </Datetime>
      </Column>
    </Container>
  );
};

export default memo(Row);
