import { memo } from 'react';
import { Props } from './types';
import { Container, DownloadIcon, Loader } from './styles';
import { useLogic } from './logic';

function ExportToCsv<T>({ data, transformer, fileName, ...props }: Props<T>) {
  const { exportData, loading } = useLogic({ data, transformer, fileName });

  return (
    <Container
      {...props}
      onClick={exportData}
      disabled={!data.length}
      data-test-id="submit-export"
    >
      {loading ? (
        <>
          <Loader />
          Exporting...
        </>
      ) : (
        <>
          <DownloadIcon />
          Export to CSV
        </>
      )}
    </Container>
  );
}

export default memo(ExportToCsv);
