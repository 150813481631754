import { css } from 'styled-components';

export const TextEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const prettyScrollbar = ({
  color,
  hoverColor,
  vertical = true,
  size = '4px',
}: {
  color?: string;
  hoverColor?: string;
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.gray2} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => color ?? theme.colors.gray2};
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => hoverColor ?? theme.colors.purple3};
    }
  }
`;

// https://a11yproject.com/posts/how-to-hide-content/
export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;
