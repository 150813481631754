import { AgreeToSText, TosLink } from './styles';

const TosAgreementText = () => (
  <AgreeToSText>
    By clicking this button, you indicate that you have read and agree to be
    bound by the{' '}
    <TosLink href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}>
      Terms of use
    </TosLink>{' '}
    and that you have read the{' '}
    <TosLink href={process.env.REACT_APP_PRIVACY_POLICY_URL}>
      Privacy policy
    </TosLink>
    .
  </AgreeToSText>
);

export default TosAgreementText;
