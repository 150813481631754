import styled, { css } from 'styled-components';
import addAlpha from 'utils/addAlpha';
import DefaultLoader from 'components/Loader';
import { ButtonProps } from './types';

export const TextWrapper = styled.span`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const Loader = styled(DefaultLoader)`
  display: flex;
  && > div {
    color: inherit;
  }
`;

export const Button = styled.button<ButtonProps>`
  align-items: center;
  background-color: transparent;
  border-radius: 0.125rem;
  border: none;
  box-shadow: 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.168),
    0 0 0.0625rem 0 rgba(0, 0, 0, 0.084), 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  height: 2.5rem;
  line-height: 1.375rem;
  min-width: 12rem;
  padding: 0.6rem 0.5rem;
  transition: all 0.2s ease;
  transition: background-color ease 300ms;
  white-space: nowrap;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};

  :disabled {
    cursor: not-allowed;
  }

  ${({ theme, $socialPlatform }) => {
    switch ($socialPlatform) {
      case 'google':
        return css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};

          :disabled {
            background-color: ${theme.colors.gray325};
          }

          &:hover:not(:disabled) {
            background-color: ${addAlpha(theme.colors.purple4, 0.025)};
          }
          &:active:not(:disabled) {
            background-color: ${addAlpha(theme.colors.purple4, 0.05)};
          }
        `;
      case 'facebook':
        return css`
          background-color: ${theme.colors.facebookButton};
          color: ${theme.colors.white};

          :disabled {
            background-color: ${theme.colors.gray325};
          }

          &:hover:not(:disabled) {
            background-color: ${theme.colors.facebookButton100};
          }
          &:active:not(:disabled) {
            background-color: ${theme.colors.facebookButton200};
          }
        `;
      case 'twitter':
        return css`
          background-color: ${theme.colors.twitterButton};
          color: ${theme.colors.white};

          &:hover:not(:disabled) {
            background-color: ${theme.colors.twitterButton100};
          }
          &:active:not(:disabled) {
            background-color: ${theme.colors.twitterButton200};
          }
        `;
      case 'microsoft':
      default:
        return css`
          background-color: ${theme.colors.microsoftButton};
          color: ${theme.colors.white};

          &:hover:not(:disabled) {
            background-color: ${theme.colors.microsoftButton100};
          }
          &:active:not(:disabled) {
            background-color: ${theme.colors.microsoftButton200};
          }
        `;
    }
  }}
`;

export const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: 2rem;
  width: 1.25rem;
`;

export const StoryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  ${Button} {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;
