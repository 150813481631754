import { nanoid } from 'nanoid';
import { FC, useMemo } from 'react';
import { Container, Row, Cell } from './styles';
import { Props } from './types';

const Placeholder: FC<Props> = ({
  numberOfRows = 5,
  animated = false,
  ...props
}) => {
  const rowIds = useMemo(
    () =>
      Array(numberOfRows)
        .fill(0)
        .map(() => nanoid(4)),
    [numberOfRows],
  );
  return (
    <Container {...props}>
      {rowIds.map((rowId) => (
        <Row key={rowId}>
          <Cell $width={176} $animated={animated} />
          <Cell $width={95} $animated={animated} />
        </Row>
      ))}
    </Container>
  );
};

export default Placeholder;
