import { AxiosError } from 'axios';
import { getSignature } from 'data/requests/signature';
import { normalizeSignature } from 'model/signature';
import { useQuery } from 'react-query';

export function useSignature({
  userId,
  accessToken,
  onError,
}: {
  userId?: string;
  accessToken?: string;
  onError?: (e: AxiosError) => void;
}) {
  const { data, status, error } = useQuery(
    [
      'getSignature',
      {
        userId,
        accessToken,
      },
    ],
    getSignature,
    {
      enabled: !!userId && !!accessToken,
      onError,
    },
  );

  const signature = data && normalizeSignature(data);
  return { signature, status, error };
}
