import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import testingVar from 'utils/testingVar';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const columns = useMemo(() => getColumns(), []);
  const formsTestingVar = testingVar('forms');

  return { columns, formsTestingVar, url };
};
