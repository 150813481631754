import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'containers/Common/Layout';
import { SnackbarProvider } from 'notistack';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import StopAutomaticRenewal from 'containers/Common/StopAutomaticRenewal';
import Subscription from 'containers/Protected/Subscription';
import Dashboard from './Dashboard';
// import Settings from './Settings';
import TeamReport from './TeamReport';
import { useConnect } from './connect';
import { StackDriverLoggingProvider } from '../../data/context/StackDriverLogging';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
let stripePromise: Promise<Stripe | null>;
if (stripePublicKey) {
  stripePromise = loadStripe(stripePublicKey);
}

const Protected = () => {
  const { isTesting, me, onSwitchAccount, logout, cannon } = useConnect();

  if (isTesting && !me) return null;

  return (
    <Elements stripe={stripePromise}>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <StackDriverLoggingProvider>
          <Layout
            onLogout={logout}
            onAccountSwitch={onSwitchAccount}
            cannon={cannon}
            {...me}
          >
            <Switch>
              <Route component={Dashboard} path="/dashboard" />
              {/* <Route component={Settings} path="/settings" /> */}
              <Route
                exact
                path="/stop-automatic-renewal"
                component={StopAutomaticRenewal}
              />
              <Route component={TeamReport} path="/team-report" />
              <Route path="*">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
            <Subscription />
          </Layout>
        </StackDriverLoggingProvider>
      </SnackbarProvider>
    </Elements>
  );
};

export default Protected;
