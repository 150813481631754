import MessageCard from 'components/MessageCard';
import StaticLayout from 'containers/Common/StaticLayout';
import { Link } from './styles';

// eslint-disable-next-line consistent-return
function getCurrentBrowser() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('Firefox/')) {
    return 'firefox';
  }
  if (userAgent.includes('Chrome/') && userAgent.includes('Safari/')) {
    return userAgent.includes('Edg/') ? 'edge' : 'chrome';
  }
  if (!userAgent.includes('Chrome/') && userAgent.includes('Safari/')) {
    return 'safari';
  }
}

const supportLinksForEachBrowser: {
  [browser in NonNullable<ReturnType<typeof getCurrentBrowser>>]: string;
} = {
  chrome:
    'https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Callow-pop-ups-and-redirects-from-a-site',
  firefox:
    'https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting',
  safari:
    'https://support.apple.com/guide/safari/block-pop-ups-sfri40696/14.0/mac/11.0',
  edge: 'https://support.microsoft.com/en-us/search?query=allow%20pop%20ups%20in%20edge#:~:text=Microsoft%20Edge%20app%20help,pop%2Dups%20on%20your%20device.',
};

function BlockedPopups() {
  const currentBrowser = getCurrentBrowser();
  const supportLink = currentBrowser
    ? supportLinksForEachBrowser[currentBrowser]
    : undefined;
  const browserName =
    currentBrowser && currentBrowser[0].toUpperCase() + currentBrowser.slice(1);
  return (
    <StaticLayout>
      <MessageCard
        variant="error"
        title="Whoops"
        subtitle={
          <>
            <p>
              Browser popups must be enabled to use the Form Publisher dashboard
            </p>
            {supportLink && (
              <Link
                href={supportLink}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Learn how to allow popups to be opened in {browserName}
              </Link>
            )}
          </>
        }
      />
    </StaticLayout>
  );
}

export default BlockedPopups;
