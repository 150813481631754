import { parseISO } from 'date-fns';

export type Input = {
  id: string;
  formTitle: string;
  totalGeneratedFilesCount: number;
  lastGeneratedFileDate: string;
  outputFolderUrl: string;
  monthlyCreatedFile: number[];
};

export const normalizeForm = (input: Input) => {
  return {
    id: input.id,
    formTitle: input.formTitle ?? 'Untitled form',
    totalGeneratedFilesCount: input.totalGeneratedFilesCount ?? 0,
    lastGeneratedFileDate: parseISO(
      input.lastGeneratedFileDate
        ? new Date(input.lastGeneratedFileDate).toISOString()
        : '',
    ).getTime(),
    outputFolderUrl: input.outputFolderUrl ?? '',
    monthlyCreatedFile: input.monthlyCreatedFile,
  };
};

export type Form = ReturnType<typeof normalizeForm>;
