import { FC } from 'react';
import Props from './types';

const Star: FC<Props> = ({ percentage = 100, id, ...rest }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...rest}>
      <path
        d="M8.00006 11.5996L11.4584 13.6913C12.0917 14.0746 12.8667 13.508 12.7001 12.7913L11.7834 8.85798L14.8417 6.20798C15.4001 5.72464 15.1001 4.80798 14.3667 4.74964L10.3417 4.40798L8.76673 0.691309C8.48339 0.0163086 7.51673 0.0163086 7.23339 0.691309L5.65839 4.39964L1.63339 4.74131C0.900061 4.79964 0.600061 5.71631 1.15839 6.19964L4.21673 8.84964L3.30006 12.783C3.13339 13.4996 3.90839 14.0663 4.54173 13.683L8.00006 11.5996Z"
        fill={`url(#fill-${id})`}
      />
      <path
        d="M8.00061 11.0156L8.25885 11.1718L11.7172 13.2635L11.7173 13.2636C11.9703 13.4167 12.28 13.1925 12.2131 12.9046L11.2965 8.97146L11.228 8.67765L11.456 8.4801L14.5143 5.8301L14.5145 5.82995C14.7396 5.63511 14.6154 5.271 14.3271 5.24807L14.3245 5.24786L14.3245 5.24785L10.2995 4.90618L9.99903 4.88068L9.88139 4.60307L8.30639 0.886398L8.30572 0.884829C8.19392 0.618469 7.80626 0.618469 7.69446 0.884828L7.69364 0.886771L7.69364 0.886769L6.11864 4.5951L6.00088 4.87237L5.70072 4.89785L1.67572 5.23952L1.67307 5.23974L1.67307 5.23973C1.38483 5.26266 1.26061 5.62678 1.48567 5.82161L1.48585 5.82176L4.54418 8.47176L4.77218 8.66932L4.70371 8.96313L3.7871 12.8962L8.00061 11.0156ZM8.00061 11.0156L7.74208 11.1714M8.00061 11.0156L7.74208 11.1714M7.74208 11.1714L4.28375 13.2547L4.28286 13.2552M7.74208 11.1714L4.28286 13.2552M4.28286 13.2552C4.02997 13.4083 3.7203 13.1843 3.78704 12.8965L4.28286 13.2552ZM11.7834 8.85798L14.8418 6.20798L11.7834 8.85798Z"
        stroke="url(#border)"
        strokeWidth="0.5"
      />

      <defs>
        <linearGradient id={`fill-${id}`}>
          <stop offset="0" stopColor="#FFEB83" />
          <stop offset={`${percentage}%`} stopColor="#FCBC14" />
          <stop offset={`${percentage}%`} stopColor="transparent" />
          <stop offset="100%" stopColor="transparent" />
        </linearGradient>
        <linearGradient
          id="border"
          x1="8.00009"
          y1="0.185059"
          x2="8.00009"
          y2="13.8149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEB83" />
          <stop offset="1" stopColor="#FCBC14" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Star;
