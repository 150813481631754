import { deleteSignature } from 'data/requests/signature';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

export function useDeleteSignature() {
  const { mutateAsync } = useMutation(deleteSignature);
  return useCallback(
    async ({ userId }: { userId: string }) => {
      await mutateAsync({ userId });
    },
    [mutateAsync],
  );
}
