import { useAuth } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';

export const useConnect = () => {
  const { onLogin, onSignUp, onSwitchAccount, clientId, loading, error } =
    useAuth();
  const isLoading = loading && !error;
  const loginTestingVar = testingVar('login');
  return {
    clientId,
    onLogin,
    onSignUp,
    onSwitchAccount,
    isLoading,
    error,
    loginTestingVar,
  };
};
