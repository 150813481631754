import { getUsers, postUser } from 'data/requests/users';
import { normalizeUser } from 'model/user';
import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';

export const useUsers = ({ userId }: { userId?: string }) => {
  const { data, status } = useQuery(['users', userId], getUsers, {
    enabled: !!userId,
  });
  const normalizedUsers = (data || []).map(normalizeUser);
  return { users: normalizedUsers, status };
};

export const useCreateUser = () => {
  const { mutateAsync, status } = useMutation(postUser);

  const createUser = useCallback(async () => {
    return mutateAsync();
  }, [mutateAsync]);

  return { createUser, status };
};
