/* eslint-disable import/no-webpack-loader-syntax */
import { useCallback, useState } from 'react';
import { useWorker } from 'utils/useWorker';
import { saveAs } from 'file-saver';
import dataToCsvUrl from 'worker-plugin/loader?name=dataToCsv!./dataToCsvWorker';
import { Props } from './types';
import { DataToCsvWorker } from './dataToCsvWorker';

export function useLogic<T>({ transformer, data, fileName }: Props<T>) {
  const [loading, setLoading] = useState(false);
  const { worker: dataToCsv } = useWorker<DataToCsvWorker>(
    dataToCsvUrl,
    'DataToCsvWorker',
  );

  const exportData = useCallback(async () => {
    setLoading(true);
    try {
      const transformedData = await transformer(data);
      const csv = await dataToCsv(transformedData);
      const file = new File([csv], `${fileName}.csv`, {
        type: 'application/csv',
      });
      setLoading(false);
      saveAs(file);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }, [data, fileName, transformer, dataToCsv]);

  return { exportData, loading };
}
