import { client } from 'data/client';
import { Input } from 'model/user';
import { QueryFunctionContext } from 'react-query';

type GetUsersResponse = {
  items: Input[];
};

export async function getUsers({ queryKey }: QueryFunctionContext) {
  const [, userId] = queryKey as [string, string];
  const { data } = await client.get<GetUsersResponse>(
    `/users/${userId}/domain/users`,
  );
  return data.items;
}

export async function getUser({ queryKey }: QueryFunctionContext) {
  const [, userId] = queryKey as [string, string];
  const { data } = await client.get<Input>(`/users/${userId}`);
  return data;
}

export async function postUser() {
  await client.post(`/users`);
}
