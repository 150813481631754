import styled from 'styled-components';
import PriceCard from '../../../components/PriceCard';
import { from } from '../../../styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1.875rem;

  background-color: ${({ theme }) => theme.colors.purple};

  ${from.tablet} {
    background-image: url('/images/background/wrong-plan/wrong-plan-background.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lexendDeca};
  font-size: 1rem;
  text-align: center;
  letter-spacing: -0.01em;
  line-height: 160%;

  margin-bottom: 0.5rem;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  text-align: center;
  letter-spacing: -0.01em;
  line-height: 160%;

  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const SignInButton = styled.button`
  border: 0.0625rem solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.medium};
  background-color: transparent;
  line-height: 160%;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  padding: 0.3125rem 1rem;

  margin-top: 0.9375rem;
  margin-bottom: 2.1875rem;
  cursor: pointer;
`;

export const BackLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration-line: underline;
  font-size: 0.875rem;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const StyledPricingCard = styled(PriceCard)`
  margin: 1.25rem 0 1.5625rem 0;

  ${from.tablet} {
    margin: 1.875rem 0 3.125rem 0;
  }
`;
